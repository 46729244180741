import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { Tag, Icon } from 'antd';

const teamTypeKey = {
  HIERARCHICAL: 'hierarchical',
  USER: 'user',
  CONTRACTOR_COMPANY: 'contractorCompany',
  CONTRACTOR_TEAM: 'contractorTeam'
};

const tagColour = {
  HIERARCHICAL: 'orange',
  USER: 'green',
  CONTRACTOR_COMPANY: 'orange',
  CONTRACTOR_TEAM: 'green'
};

const tagIcon = {
  HIERARCHICAL: 'apartment',
  USER: 'user',
  CONTRACTOR_COMPANY: 'apartment',
  CONTRACTOR_TEAM: 'user'
};

const TeamType = ({
  team,
  t,
}) => {
  const { teamType } = team;
  return (
    <Tag color={tagColour[teamType]}>
      <Icon key={teamType} type={tagIcon[teamType]} />
      {' '}
      {t(teamTypeKey[teamType])}
    </Tag>
  );
};

TeamType.propTypes = {
  t: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired
};

export default withTranslation(['teamTypes'])(TeamType);
