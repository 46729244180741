import Config from '../Config';

const APP_CONFIG_SET_ACTION = 'APP_CONFIG_SET';

export function appConfigDefaultState() {
  return null;
}

export async function getAppConfig(dispatch) {
  const result = await fetch(`${Config.apiGateway.INTERNAL_API_URL}/app-config`);
  const payload = await result.json();
  const data = {
    maintenanceMode: payload.maintenance_mode,
    appVersion: payload.app_version,
    crmServiceEnabled: payload.crm_service_enabled,
    supportedCharacterSets: payload.supported_character_sets,
    contractorTypeOptions: payload.contractor_type_options,
    enableContractorType: payload.enable_contractor_type,
  };
  dispatch({
    type: APP_CONFIG_SET_ACTION,
    payload: data
  });
}

export default (state = appConfigDefaultState(), action) => {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
};
