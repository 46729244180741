import { api, normaliseResponse } from './api';

const RISK_PROFILE_GET_ACTION = 'RISK_PROFILE_GET';
const RISK_PROFILE_CLEAR_DATA_ACTION = 'RISK_PROFILE_CLEAR_DATA';

const riskProfileRelationships = ['task', 'criticalRisk', 'control'];

function transformAttributes(riskProfile) {
  const attributes = {
    task: null,
    team: riskProfile.team,
    criticalRisk: []
  };

  const tasks = [];

  riskProfile.items.forEach((item, index) => {
    if (item.task) {
      tasks.push(item.task);
    }

    item.risks.forEach((risk) => {
      const { uuid, name, control } = risk;
      attributes.criticalRisk.push(
        {
          uuid,
          name,
          taskId: (item.task && item.task.id) ? item.task.id : null,
          order: index,
          control: control.map((c) => ({ uuid: c.uuid, name: c.name }))
        }
      );
    });
  });

  if (tasks.length > 0) {
    attributes.task = tasks;
  }

  return attributes;
}

function mapRiskAttributes(risk) {
  return risk.map((item) => {
    const { uuid, name, control } = item;
    return { uuid, name, control };
  });
}

function tabulateRiskProfile(riskProfile) {
  const data = {
    uuid: riskProfile.uuid,
    team: riskProfile.team,
    items: []
  };

  const usedTasks = {};
  const tasks = riskProfile.task || [];
  const risks = riskProfile.criticalRisk || [];

  // sort by order of index
  risks.sort((a, b) => a.order - b.order);

  // iterate over each risk and add as separate item row
  risks.forEach((risk) => {
    // non task risks, each item on a separate row
    if (!risk.task) {
      data.items.push({
        risks: mapRiskAttributes([risk])
      });
    } else {
      // task related risks, group each risk by task
      if (usedTasks[risk.task.taskId]) {
        return;
      }

      data.items.push(
        {
          task: tasks.filter((t) => t.id === risk.task.taskId)[0],
          risks: mapRiskAttributes(
            riskProfile.criticalRisk.filter(
              (r) => r.task && r.task.taskId === risk.task.taskId
            )
          )
        }
      );

      usedTasks[risk.task.taskId] = true;
    }
  });

  return data;
}

export function riskProfileDefaultState() {
  return {
    item: {
      uuid: null,
      team: null,
      items: []
    }
  };
}

export async function getRiskProfile(dispatch, token, teamUuid) {
  const params = {
    endpoint: `team/${teamUuid}/riskprofile`
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: RISK_PROFILE_GET_ACTION,
    payload
  });
}

export async function saveRiskProfile(dispatch, token, teamUuid, payload) {
  const { uuid } = payload;

  const params = {
    endpoint: 'riskprofile',
    method: uuid ? 'PATCH' : 'POST',
    body: {
      data: {
        type: 'riskProfile',
        id: uuid,
        attributes: transformAttributes({
          ...payload,
          team: teamUuid
        })
      }
    }
  };

  const result = await api(dispatch, token, params);

  dispatch({
    type: RISK_PROFILE_GET_ACTION,
    payload: result
  });
}

export function clearRiskProfileData(dispatch) {
  dispatch({
    type: RISK_PROFILE_CLEAR_DATA_ACTION,
  });
}

export default (state = riskProfileDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case RISK_PROFILE_GET_ACTION: {
      const normalisedResponse = normaliseResponse(
        payload.data,
        riskProfileRelationships
      );
      return {
        ...state,
        item: tabulateRiskProfile(normalisedResponse)
      };
    }

    case RISK_PROFILE_CLEAR_DATA_ACTION:
      return {
        ...riskProfileDefaultState()
      };

    default:
      return state;
  }
};
