import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { Spin } from 'antd';
import UpsertTeam from '../UpsertTeam';
import BaseTeamForm from '../../Containers/TeamForm';

class TeamUpdate extends UpsertTeam {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };

    this.updateCurrentTeam = this.updateCurrentTeam.bind(this);
    this.setLoading = this.setLoading.bind(this);
  }

  setLoading(value) {
    this.setState({ isLoading: value });
  }

  handleErrorMessage(message) {
    const { t, teams } = this.props;
    if (message) {
      if (message.includes('own parent')) {
        return t('teamParents.errors.ownParent');
      } if (message.includes('immediate child')) {
        return t('assignGrandChild');
      } if (message.includes('with uuid')) {
        const uuid = message.split('`')[1];
        const teamName = teams.filter((team) => team.uuid === uuid)[0].name;
        return t('teamParents.errors.childParent', { teamName });
      } if (message.includes('members have not been added')) {
        return t('newTeamDeviceCode.errors.noMembers');
      } if (message.includes('following members are disabled')) {
        const disabledMembersList = message.split(':')[1];
        return t('newTeamDisabledMembers.errors.disabledMembers', { disabledMembersList });
      } if (message.includes('inactive or not within company scope')) {
        const invalidMember = message.split(':')[1];
        return t('teamInvalidMember.errors.invalidMember', { invalidMember });
      }
      if (message.includes('Unable to update team. Exact duplicate found')) {
        const displayName = message.split('-')[1];
        return t('duplicateTeam.updateTeam', { displayName });
      }
    }

    return t('common:unexpectedError');
  }

  async updateCurrentTeam(team) {
    const { updateTeam, fetchList } = this.props;

    try {
      this.setLoading(true);
      const result = await Promise.allSettled([
        this.dispatchWithAuth(updateTeam, team),
        fetchList(),
      ]);
      this.setLoading(false);
      if (result[0].status === 'fulfilled') {
        return result;
      }

      const errorType = this.handleErrorMessage(result[0].reason.message[0].detail);
      this.displayErrorMessage(errorType);
      return false;
    } catch (e) {
      this.setLoading(false);
      const errorType = this.handleErrorMessage(e.message[0].detail);
      this.displayErrorMessage(errorType);
      return false;
    }
  }

  dispatchWithAuth(func, ...args) {
    return super.dispatchWithAuth(func, ...args);
  }

  render() {
    const {
      currentTeam, closeDrawer, teams, isEditTeam, companies
    } = this.props;
    const { isLoading } = this.state;
    return (
      <Spin
        spinning={isLoading}
        size="large"
        style={{
          top: '20%',
          bottom: '20%'
        }}
      >
        <BaseTeamForm
          teams={teams}
          closeDrawer={closeDrawer}
          currentTeam={currentTeam}
          handleSave={UpsertTeam.handleSave(this.updateCurrentTeam)}
          isEditTeam={isEditTeam}
          companies={companies}
          loading={this.setLoading}
        />
      </Spin>
    );
  }
}

TeamUpdate.propTypes = {
  currentTeam: PropTypes.object,
  closeDrawer: PropTypes.func.isRequired,
  teams: PropTypes.object.isRequired,
  isEditTeam: PropTypes.bool.isRequired,
  fetchList: PropTypes.func.isRequired,
};

export default withTranslation(['teamsForm', 'common'])(TeamUpdate);
