import { connect } from 'react-redux';
import BatchTransactionList from '../../Components/BatchTransactionList';
import {
  getBatchTransactions
} from '../../reducers/team';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  transactions: state.team.transactions.data,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getBatchTransactions(...args) {
    return getBatchTransactions(dispatch, ...args);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithDefaults(BatchTransactionList));
