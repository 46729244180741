import { connect } from 'react-redux';
import TeamTransactionView from '../../Components/TeamTransactionView';
import {
  getBatchLogs,
  getBatchStatus,
  terminateTransaction
} from '../../reducers/team';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getBatchStatus(...args) {
    return getBatchStatus(dispatch, 'batchUpsert', ...args);
  },
  getBatchLogs(...args) {
    return getBatchLogs(dispatch, ...args);
  },
  terminateTransaction(...args) {
    return terminateTransaction(dispatch, ...args);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithDefaults(TeamTransactionView));
