import { api } from './api';

const SITE_GET_LIST_ACTION = 'SITE_GET_LIST_ACTION';
const SITE_CLEAR_DATA_ACTION = 'SITE_CLEAR_DATA_ACTION';

export function siteDefaultState() {
  return {
    list: []
  };
}

export async function getSites(dispatch, token, companyUuid) {
  const params = {
    endpoint: `site/${companyUuid}`
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: SITE_GET_LIST_ACTION,
    payload
  });
}

export function clearSiteData(dispatch) {
  dispatch({
    type: SITE_CLEAR_DATA_ACTION
  });
}

export default (state = siteDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case SITE_GET_LIST_ACTION:
      return {
        ...state,
        list: payload.data
      };

    case SITE_CLEAR_DATA_ACTION:
      return {
        ...siteDefaultState()
      };

    default:
      return state;
  }
};
