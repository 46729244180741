import { connect } from 'react-redux';

import NewTeam from '../../Components/NewTeam';
import { createTeam } from '../../reducers/team';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (ownProps) => ({
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  createTeam(...args) {
    return createTeam(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(NewTeam));
