import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import {
  Col,
  Row,
  Input,
  Select,
  Card,
  Button,
  Dropdown,
  Menu,
  Icon
} from 'antd';
import FilterOptions from '../../Containers/TeamFilter/FilterOptions';
import TeamExporter from '../../Containers/TeamExporter';
import styles from './style.module.scss';

const ALL = 'ALL';
const ACTIVE = 'ACTIVE';
export class TeamFilter extends Component {
  constructor(props) {
    super(props);
    this.initialFilterValues = {
      'filter.name': undefined,
      'filter.company': undefined,
      'filter.member': undefined,
      'filter.teamType': undefined,
      'filter.owner': undefined,
      'filter.status': ACTIVE,
      'filter.includeSubLevelCompanies': true
    };
    this.initialState = {
      searchBy: 'name',
      showFilter: false,
      filters: this.initialFilterValues
    };
    this.state = this.initialState;
    this.resetFilter = this.resetFilter.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFiltersApply = this.handleFiltersApply.bind(this);
    this.searchInput = React.createRef();
  }

  handleFilterChange(name, value) {
    if (!name) return;
    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [`filter.${name}`]: value
      }
    }));
  }

  handleFiltersApply() {
    const { onFilter } = this.props;
    const { searchBy, filters } = this.state;

    const { value } = this.searchInput.current.input.state;
    let filterCopy = {
      ...filters
    };
    if (!value) delete filterCopy[`filter.${searchBy}`];
    filterCopy = {
      ...filters,
      ...(value && { [`filter.${searchBy}`]: value })
    };
    onFilter(this.validateFilterValues(filterCopy));
  }

  applyFilter(key, value) {
    this.handleFilterChange(key, value);
    this.handleFiltersApply();
  }

  validateFilterValues(data) {
    const { currentFilter } = this.props;
    const { filters } = this.state;
    const validatedFilters = {
      ...currentFilter,
      ...(data || filters || {})
    };
    Object.keys(data).forEach((key) => {
      const value = data[key];
      switch (key) {
        case 'filter.status':
          if (value === ALL) delete validatedFilters['filter.status'];
          else if (!value) delete validatedFilters['filter.status'];
          break;
        case 'filter.member':
          if (!(value || []).length) delete validatedFilters['filter.member'];
          break;
        default:
          // null == undefined = true
          if (value == null || value === '') delete validatedFilters[key];
          break;
      }
    });
    return validatedFilters;
  }

  resetFilter() {
    const { onFilter } = this.props;
    this.setState({
      searchBy: this.initialState.searchBy,
      filters: this.initialFilterValues
    });
    this.searchInput.current.input.state.value = '';
    onFilter({
      'filter.status': ACTIVE
    });
  }

  toggleFilter(state) {
    this.setState({ showFilter: state });
  }

  renderButtons(isMobile) {
    const { t, currentFilter } = this.props;
    const { showFilter } = this.state;

    return (
      <Button.Group style={isMobile ? { marginTop: 30 } : { float: 'right' }}>
        <TeamExporter
          filter={currentFilter}
          buttonText={t('export')}
          style={isMobile ? { width: '100%', marginBottom: 15 } : {}}
        />
        <Dropdown trigger={['click']} overlay={this.renderBatchProcessMenu()}>
          <Button
            icon="retweet"
            style={isMobile ? { width: '100%', marginBottom: 15 } : {}}
          >
            {t('batchProcess')}
          </Button>
        </Dropdown>
        <Button
          className={styles.showFilter}
          icon="filter"
          onClick={() => this.toggleFilter(!showFilter)}
        >
          {showFilter ? t('hideFilterOptions') : t('showFilterOptions')}
        </Button>
      </Button.Group>
    );
  }

  renderBatchProcessMenu() {
    const { t } = this.props;
    return (
      <Menu>
        <Menu.Item key="new-batch-transaction">
          <Link to="/teams/batch-process">
            <Icon type="upload" />
            {' '}
            {t('newBatchProcess')}
          </Link>
        </Menu.Item>
        <Menu.Item key="view-batch-transaction">
          <Link to="/teams/batch-transactions">
            <Icon type="bars" />
            {t('transactionsList')}
          </Link>
        </Menu.Item>
      </Menu>
    );
  }

  render() {
    const { t, onFilter, currentFilter, companies } = this.props;
    const { searchBy, showFilter, filters } = this.state;
    const searchKeys = ['name'];

    return (
      <Media query="(max-width: 769px)">
        {(isMobile) => (
          <>
            <Card>
              <Row>
                <h3>{t('searchBoxTitle')}</h3>
                <div className={styles.container}>
                  <Col md={12} sm={24}>
                    <Input.Group compact>
                      <Select
                        defaultValue="name"
                        dropdownMatchSelectWidth={false}
                        onChange={(value) => this.setState({ searchBy: value })}
                        value={searchBy}
                        style={{ width: isMobile ? '100%' : '8rem' }}
                      >
                        {searchKeys.map((key) => (
                          <Select.Option key={key} value={key}>
                            {t(key)}
                          </Select.Option>
                        ))}
                      </Select>
                      <Input.Search
                        allowClear
                        id="search-input"
                        ref={this.searchInput}
                        onSearch={(value) => this.applyFilter(searchBy, value)}
                        defaultValue=""
                        style={{ width: isMobile ? '100%' : '19rem' }}
                        placeholder={t(`searchPlaceHolder.${searchBy}`)}
                      />
                    </Input.Group>
                  </Col>
                  {this.renderButtons(isMobile)}
                </div>
              </Row>
            </Card>
            <FilterOptions
              onFilter={onFilter}
              filters={filters}
              setFilters={this.handleFilterChange}
              currentFilter={currentFilter}
              companies={companies}
              visible={showFilter}
              resetFilter={this.resetFilter}
              handleFiltersApply={this.handleFiltersApply}
            />
          </>
        )}
      </Media>
    );
  }
}

TeamFilter.defaultProps = {
  currentFilter: {}
};

TeamFilter.propTypes = {
  t: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  currentFilter: PropTypes.object,
  companies: PropTypes.object.isRequired
};

export default withTranslation(['teams'])(TeamFilter);
