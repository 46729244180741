import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import {
  Row, Col, Spin, Divider, Button, Select, Popover, Icon, Tag, Modal, Typography
} from 'antd';
import { Base, ResponsiveTable } from 'componentlibrary';
import styles from './styles.module.scss';

const { Title } = Typography;

export class RiskProfile extends Base {
  static pluralize(count, entity) {
    return `${entity}${count === 1 ? '' : 's'}`;
  }

  static renderTaskWithRisksLabel(task) {
    const riskCount = task.risks.length;
    if (riskCount === 0) {
      return task.name;
    }

    return `${task.name} (with ${riskCount} ${RiskProfile.pluralize(
      riskCount,
      'risk'
    )})`;
  }

  static renderControlLabelCount(controls) {
    const count = controls.length;
    return `${count} ${RiskProfile.pluralize(count, 'control')}`;
  }

  static transformRiskControl(risk) {
    return (
      <div>
        {risk.name}
        <Popover
          content={risk.control.map((c) => (
            <Tag color="green">{c.name}</Tag>
          ))}
        >
          <Tag className={styles.controlTag} color="green">
            <Icon type="safety-certificate" />
            {RiskProfile.renderControlLabelCount(risk.control)}
          </Tag>
        </Popover>
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      riskProfile: {},
      loadingRiskProfile: true,
      savingRiskProfile: false,
      loadingTasks: true,
      isDirty: false,
      showRemoveAllModal: false,
    };

    this.loadingRiskProfile = this.loadingRiskProfile.bind(this);
    this.fetchRiskProfile = this.fetchRiskProfile.bind(this);
    this.loadingTasks = this.loadingTasks.bind(this);
    this.savingRiskProfile = this.savingRiskProfile.bind(this);
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.save = this.save.bind(this);
    this.handleCloseRemoveAllModal = this.handleCloseRemoveAllModal.bind(this);
    this.handleRemoveAllTask = this.handleRemoveAllTask.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  get teamUuid() {
    const { match } = this.props;
    return match.params.uuid;
  }

  get taskOptions() {
    const { tasks } = this.props;
    return tasks.list.map(
      (task) =>
        !this.hasTask(task.id) && (
          <Select.Option key={`task_${task.id}`}>
            <Tag className={styles.tag} color="gold">
              TASK
            </Tag>
            {RiskProfile.renderTaskWithRisksLabel(task)}
          </Select.Option>
        )
    );
  }

  get riskOptions() {
    const { risks } = this.props;
    return risks.list.map(
      (risk) =>
        !this.hasRisk(risk.id) && (
          <Select.Option key={`risk_${risk.id}`}>
            <Tag className={styles.tag} color="volcano">
              RISK
            </Tag>
            {risk.name}
          </Select.Option>
        )
    );
  }

  get tableHeader() {
    const { t } = this.props;

    const headerItem = (key, label, width) => ({
      key,
      label,
      width
    });

    return [
      headerItem('task', t('task'), '40%'),
      headerItem('risks', t('criticalRisk'), '40%'),
      headerItem('actions', '', '10%')
    ];
  }

  get riskProfileItems() {
    const { t } = this.props;
    const { riskProfile, loadingRiskProfile, isDirty } = this.state;

    if (loadingRiskProfile) {
      return [];
    }

    if (riskProfile && riskProfile.items) {
      if (riskProfile.items.length === 0 && isDirty) {
        return [
          {
            task: {
              name: ''
            },
            risks: [],
            hideActions: true
          }
        ];
      }
    }

    if (riskProfile && riskProfile.items) {
      if (riskProfile.items.length === 0) {
        return [
          {
            task: {
              name: t('noRiskProfile')
            },
            risks: [],
            hideActions: true
          }
        ];
      }
    }

    return riskProfile.items;
  }

  get referrer() {
    const { location } = this.props;
    if (
      location.search.includes('referrer') &&
      location.search.includes('targets')
    ) {
      return 'targets';
    }

    return 'teams';
  }

  componentDidMount() {
    this.setState({});
    this.fetchRiskProfile(this.teamUuid);
    this.fetchTeam(this.teamUuid);
  }

  componentDidUpdate(prevProps) {
    const { team, riskProfile } = this.props;

    if (
      (!prevProps.team.uuid && team.uuid) ||
      prevProps.team.uuid !== team.uuid
    ) {
      this.fetchTasksAndRisks(team.company.uuid);
    }

    if (riskProfile !== this.state.riskProfile) {
      this.setState({ riskProfile });
    }
  }

  componentWillUnmount() {
    const { clearSelectedTeam, clearRiskProfileData } = this.props;
    clearSelectedTeam();
    clearRiskProfileData();
  }

  async fetchRiskProfile(teamUuid) {
    const { t, getRiskProfile } = this.props;

    try {
      this.loadingRiskProfile(true);
      await super.dispatchWithAuth(getRiskProfile, teamUuid);
      super.onDataRetrieved();
    } catch (err) {
      // Unauthorized errors are already handled in the reducer
      if (err.status === 401 || err.status === 404) {
        return;
      }
      console.error(err);
      super.handleError(t('fetchError'));
    } finally {
      this.loadingRiskProfile(false);
    }
  }

  async fetchTeam(uuid) {
    const { t, getTeam } = this.props;
    try {
      await super.dispatchWithAuth(getTeam, uuid);
      super.onDataRetrieved();
    } catch (err) {
      if (err.status === 404) {
        super.handleError(t('erorrs:404'), t('errors:notFound'));
        return;
      }
      this.handleError(err);
    }
  }

  async fetchTasksAndRisks(companyUuid, filter = {}) {
    const { getTasks, getRisks, clearRiskData, clearTaskData } = this.props;
    try {
      this.loadingTasks(true);
      await Promise.all([
        super.dispatchWithAuth(clearRiskData),
        super.dispatchWithAuth(clearTaskData),
        super.dispatchWithAuth(getRisks, companyUuid, filter),
        super.dispatchWithAuth(getTasks, companyUuid, filter)
      ]);
      await super.onDataRetrieved();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.loadingTasks(false);
    }
  }

  async save() {
    const { riskProfile } = this.state;
    const { saveRiskProfile } = this.props;
    try {
      this.savingRiskProfile(true);
      await super.dispatchWithAuth(saveRiskProfile, this.teamUuid, riskProfile);
      super.onDataRetrieved();
      this.setState({
        isDirty: false,
        showRemoveAllModal: false
      });
    } catch (err) {
      this.handleError(err);
    } finally {
      this.savingRiskProfile(false);
    }
  }

  backToReferrer() {
    const { history } = this.props;
    const { referrer } = this;

    if (referrer === 'targets') {
      history.push(`/targets?team_uuid=${this.teamUuid}`);
    } else {
      history.push('/teams');
    }
  }

  handleError(err) {
    const { t } = this.props;
    // Unauthorized errors are already handled in the reducer
    if (err.status === 401) {
      return;
    }
    console.error(err);
    super.handleError(t('common:unexpectedErrorMessage'));
  }

  hasTask(id) {
    const { riskProfile } = this.props;
    return (
      riskProfile.items.filter((item) => item.task && item.task.id === id)
        .length > 0
    );
  }

  hasRisk(id) {
    const { riskProfile } = this.props;
    return (
      riskProfile.items.filter((item) => item.risks && item.risks[0].id === id)
        .length > 0
    );
  }

  transformDataForDisplay(data) {
    if (!data) {
      return [];
    }

    return data.map((_item, index) => {
      const item = { ..._item };

      item.task = item.task ? item.task.name : '-';
      item.risks = item.risks.map((risk) =>
        RiskProfile.transformRiskControl(risk));
      item.actions = !item.hideActions && this.renderActions(index);

      return item;
    });
  }

  renderActions(index) {
    const { t } = this.props;
    return (
      <Col style={{ textAlign: 'right', marginRight: 10 }}>
        <Button
          icon="delete"
          onClick={() => this.removeItem(index)}
          title={t('remove')}
        />
      </Col>
    );
  }

  updateSearchQuery(value) {
    const { team } = this.props;
    if (team.company.uuid && value) {
      this.fetchTasksAndRisks(team.company.uuid, { 'filter.name': value });
    } else if (team.company.uuid) {
      this.fetchTasksAndRisks(team.company.uuid);
    }
  }

  addItem(key) {
    const parts = key.split('_');
    const type = parts[0];
    const id = parts[1];

    if (type === 'task') {
      return this.addTask(id);
    }

    return this.addRisk(id);
  }

  addTask(id) {
    const { riskProfile } = this.state;
    const { tasks } = this.props;
    const task = tasks.hash[id];

    riskProfile.items.push({
      task: {
        id: task.id,
        name: task.name
      },
      risks: task.risks
    });

    this.setState({ riskProfile });
  }

  addRisk(id) {
    const { riskProfile } = this.state;
    const { risks } = this.props;
    const risk = risks.hash[id];

    riskProfile.items.push({
      risks: [risk]
    });

    this.setState({ riskProfile });
  }

  removeItem(index) {
    const { riskProfile } = this.state;
    riskProfile.items.splice(index, 1);

    this.setState({
      riskProfile,
      isDirty: true
    });
  }

  loadingRiskProfile(state) {
    this.setState({ loadingRiskProfile: state });
  }

  savingRiskProfile(state) {
    this.setState({ savingRiskProfile: state });
  }

  loadingTasks(state) {
    this.setState({ loadingTasks: state });
  }

  handleCloseRemoveAllModal() {
    this.setState({ showRemoveAllModal: false });
  }

  handleRemoveAllTask() {
    this.save();
  }

  handleSaveButtonClick() {
    const { riskProfile, isDirty } = this.state;
    if (riskProfile && riskProfile.items) {
      if (riskProfile.items.length === 0 && isDirty) {
        this.setState({ showRemoveAllModal: true });
        return;
      }
    }
    this.save();
  }

  render() {
    const { t, team } = this.props;
    const { loadingRiskProfile, savingRiskProfile, loadingTasks, riskProfile } =
      this.state;

    return (
      <Media query="(max-width: 769px)">
        {() => (
          <>
            <Row type="flex" justify="center" align="top">
              <Col span={24}>
                <Title level={4} className={styles.title}>
                  {t('teamRiskProfile')}
                  {team.name && <Tag>{team.displayName}</Tag>}
                </Title>
                <Divider />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Spin
                  spinning={!team || loadingRiskProfile || savingRiskProfile}
                  size="large"
                  style={{
                    marginTop: 40,
                    position: 'fixed',
                    top: '20%',
                    bottom: '20%'
                  }}
                >
                  <Row style={{ marginBottom: 20 }}>
                    <Col>
                      <Select
                        showSearch
                        filterOption={false}
                        value={[]}
                        loading={loadingTasks}
                        style={{ width: '100%' }}
                        placeholder={loadingTasks ? '' : t('addNew')}
                        optionFilterProp="children"
                        onSelect={(id) => this.addItem(id)}
                        onSearch={debounce(
                          (value) => this.updateSearchQuery(value),
                          800
                        )}
                        notFoundContent={
                          loadingTasks ? <Spin size="small" /> : null
                        }
                      >
                        {this.taskOptions}
                        {this.riskOptions}
                      </Select>
                    </Col>
                  </Row>
                  <Row>
                    <ResponsiveTable
                      header={this.tableHeader}
                      data={this.transformDataForDisplay(this.riskProfileItems)}
                    />
                  </Row>
                  {
                    !loadingRiskProfile && (
                      <Row style={{ marginTop: 40 }}>
                        <Col>
                          <Button
                            style={{ right: 5 }}
                            type="primary"
                            onClick={this.handleSaveButtonClick}
                            disabled={(riskProfile && riskProfile.items &&
                              riskProfile.items.length === 0 &&
                              !this.state.isDirty) || false}
                          >
                            {t('save')}
                          </Button>
                          <Button onClick={() => this.backToReferrer()}>
                            <Icon type="swap-left" />
                            {' '}
                            {t(`${this.referrer === 'teams' ? 'backToTeams' : 'backToTargets'}`)}
                          </Button>
                        </Col>
                      </Row>
                    )
                  }
                </Spin>
              </Col>
            </Row>
            <Modal
              title={t('removeAllTask')}
              visible={this.state.showRemoveAllModal}
              onOk={this.handleRemoveAllTask}
              onCancel={this.handleCloseRemoveAllModal}
            >
              {t('removeAllTaskGuidance')}
            </Modal>
          </>
        )}
      </Media>
    );
  }
}

RiskProfile.defaultProps = {
  riskProfile: {},
  team: {},
  tasks: {
    list: [],
    hash: {}
  },
  risks: {
    list: [],
    hash: {}
  }
};

RiskProfile.propTypes = {
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  getRiskProfile: PropTypes.func.isRequired,
  saveRiskProfile: PropTypes.func.isRequired,
  riskProfile: PropTypes.object,
  getTeam: PropTypes.func.isRequired,
  team: PropTypes.object,
  getTasks: PropTypes.func.isRequired,
  tasks: PropTypes.object,
  getRisks: PropTypes.func.isRequired,
  risks: PropTypes.object,
  clearRiskProfileData: PropTypes.func.isRequired,
  clearSelectedTeam: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withTranslation(['riskProfile', 'common', 'errors'])(
  withRouter(RiskProfile)
);
