import 'core-js/stable';
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import App from './Containers/App';
import i18n from './translations/i18n';
import registerServiceWorker from './registerServiceWorker';
import store from './store';

import './sass/base.scss';

window.renderTeamsMicroFrontend = (containerId, history, context) => {
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App history={history} context={context} />
      </Provider>
    </I18nextProvider>,
    document.getElementById(containerId)
  );
};

window.unmountTeamsMicroFrontend = (containerId) => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
};

registerServiceWorker();
