const AUTH_SET_ACTION = 'AUTH_SET';

export function authDefaultState() {
  return {
    getIdToken: null,
    permission: null,
    user: null,
    currentLanguage: null
  };
}

export function setAuth(dispatch, getIdToken, permission, user, currentLanguage) {
  return dispatch({
    type: AUTH_SET_ACTION,
    payload: {
      getIdToken,
      permission,
      user,
      currentLanguage
    }
  });
}

export default (state = authDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case AUTH_SET_ACTION:
      return {
        getIdToken: payload.getIdToken,
        permission: payload.permission,
        user: payload.user,
        currentLanguage: payload.currentLanguage
      };

    default:
      return state;
  }
};
