import { connect } from 'react-redux';

import TeamDrawer from '../../Components/TeamDrawer';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (ownProps) => ({
  ...ownProps,
});

export default connect(mapStateToProps)(WithDefaults(TeamDrawer));
