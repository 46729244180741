import { api, serialize } from './api';

const COMPANY_GET_LIST_ACTION = 'COMPANY_GET_LIST';
const COMPANY_CLEAR_DATA_ACTION = 'COMPANY_CLEAR_DATA';
const COMPANY_GET_CHILDREN_ACTION = 'COMPANY_GET_CHILDREN';
const COMPANY_GET_STRUCTURE_DATA_ACTION = 'COMPANY_GET_STRUCTURE';
const COMPANY_GET_CONTRACTOR_COMPANY_LIST_ACTION = 'COMPANY_GET_CONTRACTOR_COMPANY_LIST';
export const COMPANY_ADD_CONTRACTOR_COMPANY_ACTION = 'COMPANY_ADD_CONTRACTOR_COMPANY';

const sortAsc = (a, b) => ((a > b) ? 1 : -1);

export function companyDefaultState() {
  return {
    list: {
      data: [],
    },
    childrenCompany: {
      data: []
    },
    contractorCompany: {
      data: []
    },
    companyStructure: null,
  };
}

export async function getCompanies(dispatch, token, filter = {}) {
  const params = {
    endpoint: 'company',
    query: serialize(filter, '&'),
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: COMPANY_GET_LIST_ACTION,
    payload,
  });
}

export async function getStructuredCompanies(dispatch, token) {
  const params = {
    endpoint: 'company',
    query: serialize({
      getCompanyStructure: 'true',
    }),
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: COMPANY_GET_STRUCTURE_DATA_ACTION,
    payload,
  });
}

export async function getChildrenCompanies(dispatch, token, parentUuid) {
  const params = {
    endpoint: 'company',
    query: `/${parentUuid}`,
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: COMPANY_GET_CHILDREN_ACTION,
    payload,
  });
}

export async function getContractorCompanies(dispatch, token) {
  const params = {
    endpoint: 'contractor-company'
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: COMPANY_GET_CONTRACTOR_COMPANY_LIST_ACTION,
    payload
  });
}

export function clearCompanyData(dispatch) {
  dispatch({
    type: COMPANY_CLEAR_DATA_ACTION,
  });
}

export default (state = companyDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case COMPANY_GET_LIST_ACTION:
      return {
        ...state,
        list: {
          data: payload.data.map((item) => item.attributes),
        },
      };

    case COMPANY_GET_CHILDREN_ACTION:
      return {
        ...state,
        childrenCompany: {
          data: payload.data.map((item) => item.attributes),
        },
      };

    case COMPANY_GET_STRUCTURE_DATA_ACTION:
      return {
        ...state,
        companyStructure: payload.data.attributes,
      };

    case COMPANY_GET_CONTRACTOR_COMPANY_LIST_ACTION:
      return {
        ...state,
        contractorCompany: {
          data: payload.data.map((item) => item.id).sort(sortAsc)
        }
      };

    case COMPANY_ADD_CONTRACTOR_COMPANY_ACTION: {
      const { contractorCompany } = state;
      const { data: contractorCompanyData } = contractorCompany;

      if (!contractorCompanyData.map(
        (value) => value.toLowerCase()
      ).includes(payload.toLowerCase())
      ) {
        contractorCompanyData.push(payload);
      }

      return {
        ...state,
        contractorCompany: {
          data: contractorCompanyData.sort(sortAsc)
        }
      };
    }

    case COMPANY_CLEAR_DATA_ACTION:
      return {
        ...companyDefaultState(),
      };

    default:
      return state;
  }
};
