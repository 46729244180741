import React from 'react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Row, Col, Spin, Divider, Pagination, Button, Icon, Typography
} from 'antd';
import { Base, ResponsiveTable, Empty } from 'componentlibrary';

import TeamFilter from '../TeamFilter';
import TeamType from '../TeamType';
import TeamDrawer from '../../Containers/TeamDrawer';
import styles from './styles.module.scss';

const { Title } = Typography;

const pageSizeOptions = ['25', '50', '100'];

export class Teams extends Base {
  static pageSizeSetting() {
    const pageSize = parseInt(sessionStorage.getItem('teamlist.pageSize'), 10);
    if (Number.isNaN(pageSize) || pageSize < 1) {
      return parseInt(pageSizeOptions[0], 10);
    }

    return pageSize;
  }

  static ownerName(item) {
    if (item.company && item.company.name) {
      return item.company.name;
    }

    return '';
  }

  constructor(props) {
    super(props);

    this.state = {
      filter: { 'filter.status': 'ACTIVE' },
      loading: false,
      limit: Teams.pageSizeSetting(),
      offset: 0,
      page: 1,
      visible: false, // for Drawer component
      currentTeam: {},
    };

    this.loading = this.loading.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onShowSizeChange = this.onShowSizeChange.bind(this);
    this.resetPagination = this.resetPagination.bind(this);
    this.showDrawer = this.showDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.fetchList = this.fetchList.bind(this);
  }

  componentDidMount() {
    this.fetchList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filter } = this.state;
    if (!equal(prevState.filter, filter)) {
      this.resetPagination(this.fetchList);
    }
  }

  get limit() {
    return this.state.limit;
  }

  get offset() {
    return this.state.offset;
  }

  get currentPage() {
    return this.offset / this.limit + 1;
  }

  get tableHeader() {
    const { t } = this.props;

    const headerItem = (key, label, width) => ({
      key,
      label,
      width,
    });

    return [
      headerItem('name', t('name'), '20%'),
      headerItem('teamType', t('teamType'), '10%'),
      headerItem('owner', t('company'), '15%'),
      headerItem('members', t('members'), '30%'),
      headerItem('status', t('status'), '10%'),
      headerItem('actions', '', '15%'),
    ];
  }

  get resultCount() {
    const { list } = this.props;
    const { meta = {} } = list;
    const { pagination = {} } = meta;
    const { resultCount = 0 } = pagination;

    return resultCount;
  }

  async fetchList() {
    const { t, getTeams } = this.props;
    const { filter, limit, page } = this.state;

    try {
      this.loading(true);
      await super.dispatchWithAuth(getTeams, filter, limit, page);
      super.onDataRetrieved();
    } catch (err) {
      // Unauthorized errors are already handled in the reducer
      if (err.status !== 401) {
        console.error(err);
        super.handleError(t('fetchError'));
      }
    } finally {
      this.loading(false);
    }
  }

  handlePageChange(page = this.currentPage, limit = this.limit) {
    this.setState(
      {
        limit,
        offset: (page - 1) * limit,
        page,
      },
      this.fetchList
    );
  }

  onShowSizeChange(current, pageSize) {
    // reset back to the first page changing the page size
    this.handlePageChange(1, pageSize);
    sessionStorage.setItem('teamlist.pageSize', pageSize);
  }

  resetPagination(then) {
    this.setState(
      {
        offset: 0,
        page: 1,
      },
      then
    );
  }

  loading(state) {
    this.setState({ loading: state });
  }

  onFilter(filter) {
    this.setState({
      filter,
      hasList: false,
    });
  }

  transformDataForDisplay(data) {
    const { t } = this.props;
    return (data || []).map((_item) => {
      const item = { ..._item };

      item.name = item.displayName;
      item.teamType = (<TeamType team={_item} />);
      item.owner = Teams.ownerName(item);
      item.members = item.children ? item.children.map((team) => team.name).join(', ') : item.members && item.members.map((member) => member.name).join(', ');
      item.status = _item.status === 'ACTIVE' ? t('activeStatus') : t('blockedStatus');
      item.actions = this.renderActions(_item);

      return item;
    });
  }

  handleCreate() {
    const team = {
      name: '',
      teamType: 'USER',
      owner: '',
      members: [],
      status: '',
      company: '',
      parents: [],
    };
    team.contractorType = this.props.enableContractorType ? '' : undefined;

    this.showDrawer(team);
  }

  showDrawer(team) {
    this.setState({
      visible: true,
      currentTeam: team,
    });
  }

  closeDrawer() {
    this.setState({
      visible: false,
    });
  }

  renderActions(team) {
    const { t, hasRiskProfileAccess, crmServiceEnabled } = this.props;
    return (
      <Col style={{ textAlign: 'right' }}>
        <Button
          icon="edit"
          onClick={() => this.showDrawer(team)}
          title={t('editTeam')}
        />
&nbsp;
        {
          (hasRiskProfileAccess && crmServiceEnabled) && (
            <Link
              title={t('editRiskProfile')}
              to={`/teams/${team.uuid}/risk-profile`}
            >
              <Button><Icon type="solution" /></Button>
            </Link>
          )
        }
      </Col>
    );
  }

  render() {
    const {
      t, list, all, companies
    } = this.props;
    const {
      loading, filter, limit, visible, currentTeam
    } = this.state;
    const { data } = list;
    const { data: allTeams } = all;
    const { resultCount } = this;

    return (
      <Media query="(max-width: 769px)">
        {(isMobile) => (
          <>
            <Row type="flex" justify="center" align="top">
              <Col span={24}>
                <Title level={4} className={styles.title}>{t('teams')}</Title>
                <Divider />
              </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  icon="user-add"
                  onClick={this.handleCreate}
                >
                  {t('createTeam')}
                </Button>
              </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <TeamFilter
                  onFilter={this.onFilter}
                  currentFilter={filter}
                  companies={companies}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Spin spinning={loading} size="large" style={{ marginTop: 40, position: 'fixed', top: '20%', bottom: '20%' }}>
                  <Row style={{ marginBottom: 20 }}>
                    <Col>
                      {resultCount > 0
                        && t('resultCount', {
                          resultCount,
                          item:
                            resultCount > 1
                              ? t('teams').toLowerCase()
                              : t('team').toLowerCase(),
                        })}
                    </Col>
                  </Row>
                  <ResponsiveTable
                    header={this.tableHeader}
                    data={this.transformDataForDisplay(data)}
                  />
                  {resultCount > 0 && (
                    <Pagination
                      className={styles.pagination}
                      showSizeChanger
                      onShowSizeChange={this.onShowSizeChange}
                      pageSizeOptions={pageSizeOptions}
                      locale={{ items_per_page: `/ ${t('common:page')}` }}
                      current={this.currentPage}
                      pageSize={limit}
                      total={resultCount}
                      showTotal={(total, range) => t('common:currentRange', {
                        rangeFrom: range[0],
                        rangeTo: range[1],
                        total,
                      })}
                      style={{ marginTop: 30 }}
                      onChange={(page) => this.handlePageChange(page)}
                    />
                  )}
                  {!loading && data && data.length === 0 && (
                    <Empty message={t('common:noRecordsFound')} />
                  )}
                </Spin>
              </Col>
            </Row>
            <TeamDrawer
              loading={this.loading}
              teams={allTeams}
              isDrawerVisible={visible}
              closeDrawer={this.closeDrawer}
              currentTeam={currentTeam}
              companies={companies}
              fetchList={this.fetchList}
              isMobile={isMobile}
            />
          </>
        )}
      </Media>
    );
  }
}

Teams.defaultProps = {
  list: {
    data: [],
  },
};

Teams.propTypes = {
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  getTeams: PropTypes.func.isRequired,
  hasRiskProfileAccess: PropTypes.bool.isRequired,
  list: PropTypes.object,
};

export default withTranslation(['teams', 'common'])(Teams);
