import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { renderRoutes } from 'react-router-config';

import Teams from './Containers/Teams';
import RiskProfile from './Containers/RiskProfile';
import BatchTeam from './Containers/BatchTeam';
import TeamTransactionView from './Containers/TeamTransactionView';
import BatchTransactionList from './Containers/BatchTransactionList';
import DACView from './Containers/DACView';

/*
  For documentation on deeply nested routes refer here:
  https://github.com/reacttraining/react-router/tree/master/packages/react-router-config
*/

const Root = ({ route }) => (
  <div>
    {/* child routes won't render without this */}
    {renderRoutes(route.routes)}
  </div>
);

Root.propTypes = {
  route: PropTypes.object.isRequired,
};

const makeRoutes = (appConfig) => [
  {
    path: '/teams',
    exact: true,
    component: Teams,
    permission: ['teams.access'],
  },
  ...(appConfig && appConfig.crmServiceEnabled ? [{
    path: '/teams/:uuid/risk-profile',
    exact: true,
    component: RiskProfile,
    permission: ['teams.access', 'teamTargets.access']
  }] : []),
  {
    path: '/teams/:uuid/dac',
    exact: true,
    component: DACView,
    permission: ['teams.access']
  },
  {
    path: '/teams/batch-process',
    exact: true,
    component: BatchTeam,
    permission: ['teams.access'],
  },
  {
    path: '/teams/transaction/:uuid',
    exact: true,
    component: TeamTransactionView,
    permission: ['teams.access'],
  },
  {
    path: '/teams/batch-transactions',
    exact: true,
    component: BatchTransactionList,
    permission: ['teams.access'],
  }
];

const routePermitted = (routePermission, permission) => routePermission.every((r) => {
  if (!lodash.get(permission, r)) {
    return false;
  }
  return true;
});

export const routesWithPermission = (permission, fallbackComponent, appConfig) => {
  const PermissionDenied = () => fallbackComponent;
  const routes = makeRoutes(appConfig);
  routes.forEach((route) => {
    if (!routePermitted(route.permission, permission)) {
      route.component = PermissionDenied;
    }
  });

  return [
    {
      component: Root,
      routes,
    }
  ];
};
