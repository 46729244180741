import { connect } from 'react-redux';

import Teams from '../../Components/Teams';
import { getTeams } from '../../reducers/team';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  list: state.team && state.team.list,
  all: state.team && state.team.all,
  companies: state.company && state.company.list,
  enableContractorType: state.appConfig && state.appConfig.enableContractorType,
  crmServiceEnabled: state.appConfig && state.appConfig.crmServiceEnabled,
  hasRiskProfileAccess:
    state.auth &&
    state.auth.permission &&
    state.auth.permission.teamTargets.access,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getTeams(...args) {
    return getTeams(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(Teams));
