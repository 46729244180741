import { connect } from 'react-redux';

import DACView from '../../Components/DACView';
import { getTeam } from '../../reducers/team';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  team: state.team.selected,
  user: state.auth.user,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getTeam(...args) {
    return getTeam(dispatch, ...args);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(DACView));
