import React from 'react';
import PropTypes from 'prop-types';

import { Spin } from 'antd';
import { withTranslation } from 'react-i18next';
import UpsertTeam from '../UpsertTeam';
import BaseTeamForm from '../../Containers/TeamForm';

class NewTeam extends UpsertTeam {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };

    this.saveTeam = this.saveTeam.bind(this);
    this.setLoading = this.setLoading.bind(this);
  }

  setLoading(value) {
    this.setState({ isLoading: value });
  }

  async saveTeam(team) {
    const { createTeam, t } = this.props;

    try {
      this.setLoading(true);
      await super.dispatchWithAuth(createTeam, team);
    } catch (e) {
      this.setLoading(false);
      let isFormatDisplayErrorMessage = false;
      let errorType = 'unexpectedErrorNewTeam';
      if (JSON.stringify(e).includes('immediate child')) {
        errorType = 'assignGrandChild';
      }
      if (JSON.stringify(e).includes('members have not been added')) {
        errorType = 'newTeamDeviceCode.errors.noMembers';
      }
      if (JSON.stringify(e).includes('Unable to create new team. Existing team found')) {
        const displayName = team.name;
        errorType = t('duplicateTeam.createTeam', { displayName });
        isFormatDisplayErrorMessage = true;
      }
      console.log(JSON.stringify(e));
      if (isFormatDisplayErrorMessage) {
        this.displayErrorMessage(errorType);
      } else {
        this.displayErrorMessage(t(errorType));
      }
      return false;
    }

    this.setLoading(false);
    return true;
  }

  render() {
    const {
      currentTeam, closeDrawer, teams, isEditTeam, companies
    } = this.props;

    const { isLoading } = this.state;
    return (
      <Spin
        spinning={isLoading}
        size="large"
        style={{
          top: '20%',
          bottom: '20%'
        }}
      >
        <BaseTeamForm
          teams={teams}
          closeDrawer={closeDrawer}
          currentTeam={currentTeam}
          handleSave={UpsertTeam.handleSave(this.saveTeam)}
          isEditTeam={isEditTeam}
          companies={companies}
          loading={this.setLoading}
        />
      </Spin>
    );
  }
}

NewTeam.prototypes = {
  currentTeam: PropTypes.object,
  closeDrawer: PropTypes.func.isRequired,
  teams: PropTypes.object.isRequired,
  isEditTeam: PropTypes.bool.isRequired
};

NewTeam.defaultProps = {
  currentTeam: {},
};

export default withTranslation(['teamsForm'])(NewTeam);
