function IdentifyCharacterSet(character, supportedCharacterSets) {
  const identifiedCharset = supportedCharacterSets.find((charset) => charset.includes(character));
  return identifiedCharset || supportedCharacterSets[0];
}

function CreateCharMap(sourceCharset, targetCharset) {
  const charMap = {};
  sourceCharset.split('').forEach((char, i) => {
    charMap[char] = targetCharset[i];
  });
  return charMap;
}

function TranslateString(input, charMap) {
  return input
    .split('')
    .map((char) => charMap[char] || char)
    .join('');
}

export function CharacterSetsBasedFiltration(
  inputValue,
  option,
  supportedCharacterSets = []
) {
  const IdentifiedCharacterSet = IdentifyCharacterSet(
    inputValue[0],
    supportedCharacterSets
  );
  const conversions = [inputValue];
  // Create conversions for each character set that is not the identified character set
  supportedCharacterSets.forEach((charset) => {
    if (charset !== IdentifiedCharacterSet) {
      conversions.push(
        TranslateString(
          inputValue,
          CreateCharMap(IdentifiedCharacterSet, charset)
        )
      );
      conversions.push(
        TranslateString(
          inputValue.toUpperCase(),
          CreateCharMap(IdentifiedCharacterSet, charset)
        )
      );
      conversions.push(
        TranslateString(
          inputValue.toLowerCase(),
          CreateCharMap(IdentifiedCharacterSet, charset)
        )
      );
    }
  });

  return conversions.some((value) =>
    option.props.children.toUpperCase().includes(value.toUpperCase()));
}
