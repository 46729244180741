import React from 'react';
import PropTypes from 'prop-types';

import { DataExporter } from 'componentlibrary';

const TeamExporter = ({
  t,
  getIdToken,
  exportInit,
  getExportStatus,
  filter,
}) => (
  <DataExporter
    t={t}
    getIdToken={getIdToken}
    exportInit={exportInit}
    getExportStatus={getExportStatus}
    filter={filter}
  />
);

TeamExporter.defaultProps = {
  filter: {},
};

TeamExporter.propTypes = {
  t: PropTypes.func.isRequired,
  getIdToken: PropTypes.func.isRequired,
  exportInit: PropTypes.func.isRequired,
  getExportStatus: PropTypes.func.isRequired,
  filter: PropTypes.object
};

export default TeamExporter;
