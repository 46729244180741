import { connect } from 'react-redux';

import FilterOptions from '../../Components/TeamFilter/FilterOptions';
import { getSites } from '../../reducers/site';
import { getMembers } from '../../reducers/member';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  sites: state.site && state.site.list,
  members: state.member && state.member.list.data,
  structureData: state.company && state.company.companyStructure,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getSites(...args) {
    return getSites(dispatch, ...args);
  },
  getMembers(...args) {
    return getMembers(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(FilterOptions));
