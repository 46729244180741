export const all = {
  key: 'ALL',
  text: 'teamsForm:teamStatus.value.all',
  value: 'ALL'
};

const active = {
  key: 'ACTIVE',
  text: 'teamsForm:teamStatus.value.active',
  value: 'ACTIVE',
};

const blocked = {
  key: 'BLOCKED',
  text: 'teamsForm:teamStatus.value.blocked',
  value: 'BLOCKED',
};

export const statusOptions = [active, blocked];

export const allStatusOptions = [all, active, blocked];
