import { connect } from 'react-redux';
import { configServiceFetchRequest, wsConnect, wsDisconnect } from 'componentlibrary';
import App from '../../Components/App';
import { getAppConfig } from '../../reducers/appConfig';
import { setAuth } from '../../reducers/auth';
import { getAllTeams } from '../../reducers/team';
import { getCompanies, getStructuredCompanies, getContractorCompanies } from '../../reducers/company';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  appConfig: state.appConfig,
  auth: state.auth,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  getAppConfig(...args) {
    return getAppConfig(dispatch, ...args);
  },
  setAuth(...args) {
    return setAuth(dispatch, ...args);
  },
  getAllTeams(...args) {
    return getAllTeams(dispatch, ...args);
  },
  getCompanies(...args) {
    return getCompanies(dispatch, ...args);
  },
  getStructuredCompanies(...args) {
    return getStructuredCompanies(dispatch, ...args);
  },
  getContractorCompanies(...args) {
    return getContractorCompanies(dispatch, ...args);
  },
  wsConnect: (value) => dispatch(wsConnect(value)),
  configServiceFetchRequest: (value) => dispatch(configServiceFetchRequest(value)),
  wsDisconnect: () => dispatch(wsDisconnect()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(App));
