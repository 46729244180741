import { isEmpty } from 'lodash';
import { api, normaliseResponse, serialize } from './api';
import { COMPANY_ADD_CONTRACTOR_COMPANY_ACTION } from './company';

const TEAM_GET_LIST_ACTION = 'TEAM_GET_LIST';
const TEAM_GET_ITEM_ACTION = 'TEAM_GET_ITEM';
const TEAM_GET_ALL_ACTION = 'TEAM_GET_ALL';
const TEAM_GET_RELATIONS_ACTION = 'TEAM_GET_RELATIONS_ACTION';
const TEAM_GET_BATCH_TRANSACTIONS_ACTION = 'TEAM_GET_BATCH_TRANSACTIONS';

const TEAM_CLEAR_SELECTED_ITEM_ACTION = 'TEAM_CLEAR_SELECTED_ITEM';
const TEAM_CLEAR_DATA_ACTION = 'TEAM_CLEAR_DATA';
const TEAM_CREATE_ACTION = 'TEAM_CREATE';
const TEAM_UPDATE_ACTION = 'TEAM_UPDATE';
const TERMINATE_TRANSACTION_ACTION = 'TERMINATE_TRANSACTION';

const teamRelationships = ['company', 'owner', 'members', 'parents', 'children', 'dacs'];

export function teamDefaultState() {
  return {
    list: {
      meta: {},
      data: [],
    },
    all: {
      data: []
    },
    relations: {
      data: []
    },
    selected: {},
    transactions: {
      data: []
    }
  };
}

// Attempt to add contractorCompanyName to the existing list of values if we are
// updating or creating a CONTRACTOR type team
function dispatchAddContractorCompanyAction(dispatch, attributes) {
  const { teamType, contractorCompanyName } = attributes;
  if (teamType.includes('CONTRACTOR')) {
    dispatch({
      type: COMPANY_ADD_CONTRACTOR_COMPANY_ACTION,
      payload: contractorCompanyName
    });
  }
}

export async function getTeam(dispatch, token, uuid) {
  const params = {
    endpoint: `team/${uuid}`
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: TEAM_GET_ITEM_ACTION,
    payload
  });
}

export async function getTeams(dispatch, token, filter, limit, page) {
  const params = {
    endpoint: 'team',
    query: serialize({
      ...filter,
      'page.size': limit,
      'page.number': page
    }),
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: TEAM_GET_LIST_ACTION,
    payload,
  });
}

export async function getRelationalTeams(dispatch, token, filter) {
  if (isEmpty(filter)) {
    dispatch({
      type: TEAM_GET_RELATIONS_ACTION,
      payload: { data: [] } },);
  }

  const params = {
    endpoint: 'team',
    query: serialize({
      ...filter
    }),
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: TEAM_GET_RELATIONS_ACTION,
    payload,
  });
}

// Fetch all teams limiting the fieldset to only uuid, name and status
// Used for populating drop downs
//
// NOTE: We only retrieve the max records (1000).
// This will be enough for now but as we roll out to larger environments that
// require more teams this will need to be made more scalable
export async function getAllTeams(dispatch, token) {
  const params = {
    endpoint: 'team',
    query: serialize({
      'fields[team]': 'uuid,displayName,status,teamType,relationships,company',
      'page.size': 1000,
    }),
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: TEAM_GET_ALL_ACTION,
    payload,
  });
}

export async function createTeam(dispatch, token, attributes) {
  const params = {
    endpoint: 'team',
    method: 'POST',
    body: {
      data: {
        type: 'team',
        attributes,
      },
    },
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: TEAM_CREATE_ACTION,
    payload,
  });

  dispatchAddContractorCompanyAction(dispatch, attributes);
}

export function clearTeamData(dispatch) {
  dispatch({
    type: TEAM_CLEAR_DATA_ACTION,
  });
}

export async function updateTeam(dispatch, token, attributes) {
  const { uuid, ...otherData } = attributes;
  const params = {
    endpoint: 'team',
    method: 'PATCH',
    body: {
      data: {
        type: 'team',
        id: uuid,
        attributes: otherData,
      },
    },
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: TEAM_UPDATE_ACTION,
    payload,
  });

  dispatchAddContractorCompanyAction(dispatch, attributes);
}

export function clearSelectedTeam(dispatch) {
  dispatch({
    type: TEAM_CLEAR_SELECTED_ITEM_ACTION
  });
}

// Note: These methods do not store data in the redux store due using a shared
// component library module
export async function exportInit(dispatch, token, filter) {
  const params = {
    query: serialize(filter, '?'),
    endpoint: 'team/export'
  };

  return api(dispatch, token, params);
}

export async function getExportStatus(dispatch, token, transactionId) {
  const params = {
    endpoint: `export-status/${transactionId}`
  };

  return api(dispatch, token, params);
}

// Batch Validation (upload)
export async function batchXlsxUpload(dispatch, token, file) {
  const params = {
    method: 'POST',
    endpoint: 'batch-xlsx-processor',
    contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    body: file
  };

  return api(dispatch, token, params);
}

// Batch Submission (by filename)
export async function submitBatch(dispatch, token, payload) {
  const params = {
    method: 'POST',
    endpoint: 'batch-team',
    body: {
      data: payload
    }
  };

  return api(dispatch, token, params);
}

// Batch transaction status by type: 'batchValidation', 'batchUpsert'
export async function getBatchStatus(dispatch, transactionType, token, transactionId) {
  const params = {
    method: 'GET',
    endpoint: 'batch-status',
    query: `?transactionType=${transactionType}&transactionId=${transactionId}`
  };

  return api(dispatch, token, params);
}

// GET /batch-xlsx-log/{transactionId}/{locale}
export async function getBatchLogs(dispatch, token, transactionId, locale) {
  const params = {
    method: 'GET',
    endpoint: `batch-logs/${transactionId}/${locale}`
  };

  return api(dispatch, token, params);
}

// POST /terminate-transaction/{transactionId}
export async function terminateTransaction(dispatch, token, attributes) {
  const params = {
    method: 'POST',
    endpoint: 'terminate-transaction',
    body: {
      data: {
        type: 'terminate-transaction',
        transactionId: attributes.id,
        terminatedBy: attributes.terminatedBy,
        terminated: attributes.terminated
      }
    }
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: TERMINATE_TRANSACTION_ACTION,
    payload
  });
  return payload;
}

function extractDataInAll(data) {
  const {
    uuid, name, status, teamType, company
  } = data;

  return {
    uuid, name, status, teamType, company
  };
}

export function normaliseTeamsResponse(data) {
  return normaliseResponse(data, teamRelationships);
}

export default (state = teamDefaultState(), action) => {
  const { payload, type } = action;
  let updatedTeam = {};
  if (type === TEAM_UPDATE_ACTION) {
    updatedTeam = normaliseTeamsResponse(payload.data);
  }

  switch (type) {
    case TEAM_GET_LIST_ACTION:
      return {
        ...state,
        list: {
          meta: payload.meta,
          data: payload.data.map((item) => normaliseTeamsResponse(item))
        }
      };

    case TEAM_GET_RELATIONS_ACTION:
      return {
        ...state,
        relations: {
          data: payload.data.map((item) => normaliseResponse(item, teamRelationships))
        }
      };

    case TEAM_GET_ITEM_ACTION:
      return {
        ...state,
        selected: normaliseTeamsResponse(payload.data)
      };

    case TEAM_GET_ALL_ACTION:
      return {
        ...state,
        all: {
          data: payload.data.map((item) => normaliseTeamsResponse(item)).map(
            (item) => ({
              ...item,
              name: item.displayName
            })
          )
        }
      };

    case TEAM_CLEAR_SELECTED_ITEM_ACTION:
      return {
        ...state,
        selected: {}
      };

    case TEAM_CLEAR_DATA_ACTION:
      return {
        ...teamDefaultState(),
      };

    case TEAM_CREATE_ACTION:
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            pagination: {
              ...state.list.meta.pagination,
              resultCount: state.list.meta.pagination.resultCount + 1
            }
          },
          data: [
            normaliseTeamsResponse(payload.data),
            ...state.list.data
          ]
        },
        all: {
          ...state.all,
          data: [
            extractDataInAll(normaliseTeamsResponse(payload.data)),
            ...state.all.data
          ],
        },
      };

    case TEAM_UPDATE_ACTION:
      return {
        ...state,
        list: {
          data: state.list.data.map((item) => {
            if (item.uuid === updatedTeam.uuid) {
              const companyOwnerTeam = ['HIERARCHICAL', 'CONTRACTOR_COMPANY'];
              if (companyOwnerTeam.includes(updatedTeam.teamType)) {
                return {
                  ...updatedTeam
                };
              }
              return updatedTeam;
            }
            return item;
          }),
          meta: state.list.meta,
        },
        all: {
          ...state.all,
          data: state.all.data.map((item) => (item.uuid === updatedTeam.uuid
            ? extractDataInAll(updatedTeam)
            : item)),
        },
      };

    case TEAM_GET_BATCH_TRANSACTIONS_ACTION:
      return {
        ...state,
        transactions: {
          data: payload.data.map((item) => normaliseTeamsResponse(item)).map(
            (item) => ({
              ...item
            })
          )
        }
      };

    case TERMINATE_TRANSACTION_ACTION:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          data: [...state.transactions.data, payload]
        }
      };

    default:
      return state;
  }
};

// get batch transaction list
export async function getBatchTransactions(dispatch, token) {
  const params = {
    endpoint: 'batch-transactions'
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: TEAM_GET_BATCH_TRANSACTIONS_ACTION,
    payload
  });
}
