export const INITIATED = 'transaction:initiated';
export const IN_PROGRESS = 'transaction:inProgress';
export const COMPLETE = 'transaction:completed';
export const ERROR = 'transaction:failed';
export const TERMINATED = 'transaction:terminated';
export const TERMINATING = 'transaction:terminating';

export const statusMap = {
  BATCH_WRITE_INITIATED: INITIATED,
  ENQUEUED: IN_PROGRESS,
  BATCH_WRITING: IN_PROGRESS,
  BATCH_WRITING_FINISHED: COMPLETE,
  BATCH_WRITING_FINISHED_WITH_ERROR: COMPLETE,
  BATCH_WRITING_UNEXPECTED_ERROR: ERROR,
  ENQUEUE_UNEXPECTED_ERROR_WHILE_ENQUEUE: ERROR,
  TERMINATED,
  TERMINATING,
};
