import * as React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  Base,
  SubmissionStep,
  SUBMISSION_INITIALSING,
  SUBMISSION_SUBMITTING,
  SUBMISSION_SUBMITTED,
  SUBMISSION_UNEXPECTED_ERROR,
  SUBMISSION_STEP
} from 'componentlibrary';

export const statusMap = {
  ENQUEUING_INITIATED: SUBMISSION_SUBMITTING,
  ENQUEUING: SUBMISSION_SUBMITTING,
  ENQUEUED: SUBMISSION_SUBMITTED,
  ENQUEUE_UNEXPECTED_ERROR_WHILE_ENQUEUE: SUBMISSION_UNEXPECTED_ERROR,
  ENQUEUE_UNEXPECTED_ERROR: SUBMISSION_UNEXPECTED_ERROR,
  BATCH_WRITING: SUBMISSION_SUBMITTED,
  BATCH_WRITING_FINISHED: SUBMISSION_SUBMITTED,
  BATCH_WRITING_UNEXPECTED_ERROR: SUBMISSION_SUBMITTED,
  BATCH_WRITING_FINISHED_WITH_ERROR: SUBMISSION_SUBMITTED,
};

export class BatchTeamSubmissionStep extends Base {
  constructor() {
    super();
    this.state = {
      transactionId: null,
      submissionState: SUBMISSION_INITIALSING,
      submissionDetail: {}
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { batchStep } = this.props;
    const { submissionState, transactionId } = this.state;

    const prevBatchStep = prevProps.batchStep;
    const prevTransactionId = prevState.transactionId;

    if (batchStep !== SUBMISSION_STEP) {
      return;
    }

    if (batchStep !== prevBatchStep) {
      this.submit();
      return;
    }

    if (submissionState === SUBMISSION_SUBMITTING && prevTransactionId !== transactionId) {
      this.getSubmissionStatus(transactionId);
    }
  }

  async getSubmissionStatus(transactionId) {
    const { t, getSubmissionStatus } = this.props;
    let result;

    try {
      result = await getSubmissionStatus(transactionId);
    } catch (err) {
      if (err.status !== 401) {
        super.handleError(t('unexpectedErrorSubmission'));
      }
      return;
    }

    const { attributes } = result;
    const status = statusMap[attributes.status];

    this.setSubmissionStatus(status, attributes.uuid);

    if (status === SUBMISSION_SUBMITTED || status === SUBMISSION_UNEXPECTED_ERROR) {
      return;
    }

    this.getSubmissionStatus(transactionId);
  }

  setSubmissionStatus(status, transactionId) {
    const newState = {
      transactionId,
      submissionState: status,
      submissionDetail: {}
    };

    if (newState.submissionState === SUBMISSION_SUBMITTED) {
      newState.submissionDetail = {
        transactionViewLink: `/teams/transaction/${transactionId}`
      };
    }

    this.setState(newState);
  }

  async submit() {
    const { batchStep, batchState, submitBatchPayload } = this.props;

    if (!batchState || batchStep !== SUBMISSION_STEP) {
      return;
    }

    let result;

    try {
      result = await submitBatchPayload({
        filename: batchState
      });

      const { attributes } = result;
      this.setSubmissionStatus(statusMap[attributes.status], attributes.uuid);
    } catch (err) {
      this.setSubmissionStatus(SUBMISSION_UNEXPECTED_ERROR);
    }
  }

  render() {
    const { batchStep, batchState, handleStepChange } = this.props;
    const { submissionState, submissionDetail } = this.state;

    return (
      <SubmissionStep
        batchStep={batchStep}
        batchState={batchState}
        handleStepChange={handleStepChange}
        submissionState={submissionState}
        submissionDetail={submissionDetail}
      />
    );
  }
}

BatchTeamSubmissionStep.propTypes = {
  batchStep: PropTypes.any.isRequired,
  batchState: PropTypes.any.isRequired,
  handleStepChange: PropTypes.func.isRequired,
  submitBatchPayload: PropTypes.func.isRequired,
  getSubmissionStatus: PropTypes.func.isRequired
};

export default withTranslation(['batchProcess'])(BatchTeamSubmissionStep);
