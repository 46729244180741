import { connect } from 'react-redux';

import WithDefaults from '../WithDefaults';
import BatchTeam from '../../Components/BatchTeam';

import {
  batchXlsxUpload,
  getBatchStatus,
  submitBatch
} from '../../reducers/team';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  batchXlsxUpload(...args) {
    return batchXlsxUpload(dispatch, ...args);
  },
  getValidationStatus(...args) {
    return getBatchStatus(dispatch, 'batchValidation', ...args);
  },
  submitBatch(...args) {
    return submitBatch(dispatch, ...args);
  },
  getSubmissionStatus(...args) {
    return getBatchStatus(dispatch, 'batchUpsert', ...args);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(BatchTeam));
