import React, { useState, useMemo, useCallback } from 'react';
import { Card, Row, Col, Select, Radio, Spin, Button, Checkbox, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import df from 'd-forest';
import { getOptions, formatOptions } from '../../Utils/Selector';
import { typeOptions } from '../../Utils/TeamTypeData';
import { allStatusOptions } from '../../Utils/StatusData';
import { CompanySelectorWithData } from '../../Containers/CompanySelector';

import styles from './style.module.scss';

const MEMBER = 'member';
const SITE = 'site';
const ACTIVE = 'ACTIVE';
const TEAM_OWNER = 'team_owner';
const USER = 'USER';
const CONTRACTOR_TEAM = 'CONTRACTOR_TEAM';

export const FilterOptions = ({
  t,
  sites,
  members,
  getIdToken,
  getSites,
  getMembers,
  setFilters,
  visible,
  resetFilter,
  structureData,
  handleFiltersApply,
  extendedSiteFilter,
  extendedOwnerFilter,
  extendedMemberFilter
}) => {
  const [loading, setLoading] = useState({
    loadingSites: false,
    loadingMembers: false,
    loadingOwners: false
  });
  const initialFilters = {
    companyStructure: undefined,
    site: undefined,
    memberList: [],
    memberFilter: undefined,
    teamType: undefined,
    teamOwner: undefined,
    status: ACTIVE,
    siteFilterVisible: extendedSiteFilter,
    teamOwnerVisible: extendedOwnerFilter,
    memberFilterVisible: extendedMemberFilter,
    includeSubLevelCompanies: true
  };
  const [companyStructure, setCompanyStructure] = useState(
    initialFilters.companyStructure
  );
  const [includeSubLevelCompanies, setIncludeSublevelCompanies] = useState(
    initialFilters.includeSubLevelCompanies
  );
  const [site, setSite] = useState(initialFilters.site);
  const [memberList, setMemberList] = useState(initialFilters.memberList);
  const [memberFilter, setMemberFilter] = useState(initialFilters.memberFilter);
  const [teamType, setTeamType] = useState(initialFilters.teamType);
  const [teamOwner, setTeamOwner] = useState(initialFilters.teamOwner);
  const [status, setStatus] = useState(initialFilters.status);
  const [showExtendedSiteFilter, setShowExtendedSiteFilter] = useState(
    initialFilters.siteFilterVisible
  );
  const [showExtendedOwnerFilter, setShowExtendedOwnerFilter] = useState(
    initialFilters.teamOwnerVisible
  );
  const [showExtendedMemberFilter, setShowExtendedMemberFilter] = useState(
    initialFilters.memberFilterVisible
  );

  const getOptionsWithTranslation = (options) => {
    if (options) {
      options.sort((a, b) => (a.text > b.text ? 1 : -1));
    }
    return getOptions(options, t);
  };

  const getSubLevelCompanies = useCallback((companies) => {
    let count = companies.length;
    companies.forEach(({ children = [] }) => {
      if (children.length) count += getSubLevelCompanies(children);
    });
    return count;
  }, []);

  const subLevelCompaniesCount = useMemo(() => {
    if (!companyStructure || !structureData) return 0;
    const filteredCompany = df.findNode(
      structureData,
      (node) => node.uuid === companyStructure
    );
    const { children = [] } = filteredCompany || {};
    return getSubLevelCompanies(children);
  }, [companyStructure, structureData, getSubLevelCompanies]);

  const fetchData = async (key, payload) => {
    const token = await getIdToken();

    if (key === SITE) {
      setLoading({
        ...loading,
        loadingSites: true
      });

      await getSites(token, payload);

      setLoading({
        ...loading,
        loadingSites: false
      });
    }

    if (key === MEMBER) {
      setLoading({
        ...loading,
        loadingMembers: true
      });

      await getMembers(token, payload);

      setLoading({
        ...loading,
        loadingMembers: false
      });
    }

    if (key === TEAM_OWNER) {
      setLoading({
        ...loading,
        laodingOwners: true
      });

      await getMembers(token, payload);

      setLoading({
        ...loading,
        loadingOwners: false
      });
    }
  };

  const clearFields = (fields) => {
    fields.forEach((field) => {
      if (field === MEMBER) {
        setMemberList(initialFilters.memberList);
        setFilters('member', initialFilters.memberList);
      } else if (field === SITE) setSite(initialFilters.site);
    });
  };

  const handleCompanySelectChange = (companyUuid) => {
    clearFields([SITE, MEMBER]);
    const siteVisible = Boolean(
      companyUuid &&
        (!teamType || teamType === CONTRACTOR_TEAM || teamType === USER)
    );
    setShowExtendedSiteFilter(siteVisible);
    if (!companyUuid) {
      setCompanyStructure(initialFilters.companyStructure);
      setFilters('company', initialFilters.companyStructure);
      return;
    }
    if (siteVisible) setSite(initialFilters.site);
    setCompanyStructure(companyUuid);
    setFilters('company', companyUuid);
    fetchData(SITE, companyUuid);
  };

  const handleSiteSelectChange = (siteUuid) => {
    clearFields([MEMBER]);
    setSite(siteUuid);
    fetchData(MEMBER, {
      ...(companyStructure && { 'filter.company': companyStructure }),
      'filter.site': siteUuid
    });
  };

  const handleMemberSelectSearch = (memberName) => {
    if (memberName) {
      setMemberFilter(memberName);
      fetchData(MEMBER, {
        ...(companyStructure && { 'filter.company': companyStructure }),
        ...(site && { 'filter.site': site }),
        'filter.name': memberName
      });
    }
  };

  const handleTeamOwnerSelectSearch = (memberName) => {
    if (memberName) {
      setTeamOwner(memberName);
      setFilters('owner', memberName);
      fetchData(TEAM_OWNER, {
        'filter.name': memberName
      });
    }
  };

  const handleIncludeSubLevelCompanies = ({ target: { checked } }) => {
    setIncludeSublevelCompanies(checked);
    setFilters('includeSubLevelCompanies', checked);
  };

  const handleTeamTypeChange = (type) => {
    const teamOwnerVisible = Boolean(type === USER || type === CONTRACTOR_TEAM);
    const memberVisible = Boolean(
      !type || type === USER || type === CONTRACTOR_TEAM
    );
    const siteVisible = Boolean(companyStructure && memberVisible);
    setTeamType(type);
    setFilters('teamType', type);
    setShowExtendedOwnerFilter(teamOwnerVisible);
    setShowExtendedMemberFilter(memberVisible);
    setShowExtendedSiteFilter(siteVisible);
    if (!teamOwnerVisible) setTeamOwner(initialFilters.teamOwner);
    if (!memberVisible) {
      clearFields([MEMBER]);
      setMemberList(initialFilters.memberList);
      setMemberFilter(initialFilters.memberFilter);
      setFilters('member', initialFilters.memberList);
    }
    if (siteVisible) {
      clearFields([SITE]);
      setSite(initialFilters.site);
    }
  };

  const handleResetClick = () => {
    setIncludeSublevelCompanies(initialFilters.includeSubLevelCompanies);
    setTeamType(initialFilters.teamType);
    setTeamOwner(initialFilters.teamOwner);
    setCompanyStructure(initialFilters.companyStructure);
    setSite(initialFilters.site);
    setMemberList(initialFilters.memberList);
    setMemberFilter(initialFilters.memberFilter);
    setStatus(initialFilters.status);
    setShowExtendedSiteFilter(initialFilters.siteFilterVisible);
    setShowExtendedOwnerFilter(initialFilters.teamOwnerVisible);
    setShowExtendedMemberFilter(initialFilters.memberFilterVisible);
    resetFilter();
  };

  const handleTeamOwner = (value) => {
    setTeamOwner(value);
    setFilters('owner', value);
  };

  const handleMemberList = (value) => {
    setMemberList(value);
    setFilters('member', value);
  };

  const handleStatus = (value) => {
    setStatus(value);
    setFilters('status', value);
  };

  const selectElement = () => (
    <Select
      placeholder={t('teamFilter.selectPlaceHolders.site')}
      style={{ width: '100%' }}
      loading={loading.loadingSites}
      disabled={loading.loadingSites}
      value={site}
      onChange={(value) => handleSiteSelectChange(value)}
      allowClear
    >
      {getOptionsWithTranslation(formatOptions(sites))}
    </Select>
  );

  return (
    <>
      {visible && (
        <Card>
          <Row>
            <Col>
              <h3>{t('teamFilter.filterOptions')}</h3>

              <div className={`type-filter ${styles.filterOptions}`}>
                <label htmlFor={t('teamFilter.teamType')}>
                  {t('teamFilter.teamType')}
                </label>
                <Select
                  placeholder={t('teamFilter.selectPlaceHolders.teamType')}
                  style={{ width: '100%' }}
                  onChange={handleTeamTypeChange}
                  value={teamType}
                  allowClear
                >
                  {getOptionsWithTranslation(typeOptions)}
                </Select>
              </div>

              {showExtendedOwnerFilter && (
                <>
                  <div className={`owner-filter ${styles.filterOptions}`}>
                    <label htmlFor={t('teamFilter.teamOwner')}>
                      {t('teamFilter.teamOwner')}
                    </label>
                    <Select
                      placeholder={t('teamFilter.selectPlaceHolders.teamOwner')}
                      style={{ width: '100%' }}
                      value={teamOwner}
                      onSearch={debounce(
                        (value) => handleTeamOwnerSelectSearch(value),
                        800
                      )}
                      onChange={(value) => handleTeamOwner(value)}
                      notFoundContent={
                        loading.loadingOwners ? <Spin size="small" /> : null
                      }
                      optionFilterProp="children"
                      allowClear
                      showSearch
                    >
                      {teamOwner
                        ? getOptionsWithTranslation(formatOptions(members))
                        : []}
                    </Select>
                  </div>
                </>
              )}

              <div className={`company-filter ${styles.filterOptions}`}>
                <label htmlFor={t('teamFilter.companyStructure')}>
                  {t('teamFilter.companyStructure')}
                </label>
                <CompanySelectorWithData
                  placeholder={t(
                    'teamFilter.selectPlaceHolders.companyStructure'
                  )}
                  style={{ width: '100%' }}
                  onChange={(value) => handleCompanySelectChange(value)}
                  value={companyStructure}
                  allowClear
                />
                {!!(companyStructure && subLevelCompaniesCount) && (
                  <div
                    className={`company-subordinate-filter ${styles.filterOption}`}
                  >
                    <Checkbox
                      onChange={handleIncludeSubLevelCompanies}
                      checked={includeSubLevelCompanies}
                    >
                      {t('teamFilter.includeSubLevelCompanies', {
                        count: subLevelCompaniesCount || 0
                      })}
                    </Checkbox>
                  </div>
                )}
              </div>

              {showExtendedSiteFilter && (
                <>
                  <div className={`site-filter ${styles.filterOptions}`}>
                    <label htmlFor={t('teamFilter.site')}>
                      {t('teamFilter.site')}
                    </label>
                    {
                      loading.loadingSites ? (
                        <Tooltip title={t('teamFilter.filterTooltipMessage.site')}>
                          {selectElement()}
                        </Tooltip>
                      ) : (
                        selectElement()
                      )
                    }
                  </div>
                </>
              )}

              {showExtendedMemberFilter && (
                <div className={`member-filter ${styles.filterOptions}`}>
                  <label htmlFor={t('teamFilter.member')}>
                    {t('teamFilter.member')}
                  </label>
                  <Select
                    mode="multiple"
                    placeholder={t('teamFilter.selectPlaceHolders.member')}
                    style={{ width: '100%' }}
                    value={memberList}
                    onSearch={debounce(
                      (value) => handleMemberSelectSearch(value),
                      800
                    )}
                    onChange={(value) => handleMemberList(value)}
                    notFoundContent={
                      loading.loadingMembers ? <Spin size="small" /> : null
                    }
                    optionFilterProp="children"
                    allowClear
                    showSearch
                  >
                    {memberFilter
                      ? getOptionsWithTranslation(formatOptions(members))
                      : []}
                  </Select>
                </div>
              )}

              <div className={`status-filter ${styles.filterOptions}`}>
                <label htmlFor={t('teamFilter.teamStatus')}>
                  {t('teamFilter.teamStatus')}
                </label>
                <Radio.Group
                  value={status}
                  style={{ display: 'block' }}
                  onChange={({ target: { value } }) => handleStatus(value)}
                >
                  {allStatusOptions.map((statusOption) => (
                    <Radio key={statusOption.value} value={statusOption.value}>
                      {t(`${statusOption.text}`)}
                    </Radio>
                  ))}
                </Radio.Group>
              </div>

              <div className={`apply-filter ${styles.filterOptions}`}>
                <Row>
                  <Col
                    md={12}
                    sm={24}
                    xs={16}
                    className={`${styles.applyFilterContainer}`}
                  >
                    <Button
                      id="reset-button-filter"
                      type="danger"
                      icon="redo"
                      onClick={handleResetClick}
                      className={`${styles.resetFilterButton}`}
                    >
                      {t('teamFilter.resetFilter')}
                    </Button>
                  </Col>
                  <Col
                    md={12}
                    sm={24}
                    xs={16}
                    className={`${styles.applyFilterContainer}`}
                  >
                    <Button
                      id="apply-button-filter"
                      type="primary"
                      icon="check"
                      onClick={handleFiltersApply}
                      className={`${styles.applyFilterButton}`}
                    >
                      {t('teamFilter.applyFilter')}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

FilterOptions.propTypes = {
  t: PropTypes.func.isRequired,
  sites: PropTypes.array.isRequired,
  members: PropTypes.array.isRequired,
  getIdToken: PropTypes.func.isRequired,
  getSites: PropTypes.func.isRequired,
  getMembers: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  resetFilter: PropTypes.func.isRequired,
  structureData: PropTypes.object.isRequired,
  handleFiltersApply: PropTypes.func.isRequired,
  extendedSiteFilter: PropTypes.bool,
  extendedOwnerFilter: PropTypes.bool,
  extendedMemberFilter: PropTypes.bool
};

FilterOptions.defaultProps = {
  extendedSiteFilter: false,
  extendedOwnerFilter: false,
  extendedMemberFilter: true
};

export default withTranslation(['teams'])(FilterOptions);
