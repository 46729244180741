import {
  Button,
  Col, Divider,
  Modal,
  Row,
  notification,
} from 'antd';
import { Base, BatchTransactionView } from 'componentlibrary';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { COMPLETE, ERROR, TERMINATED, statusMap } from '../../Utils/BatchTransactionStatus';
import { getcurrentlanguage } from '../../translations/languageOptions';

export class TeamTransactionView extends Base {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: true,
      status: null,
      total: 0,
      success: 0,
      failed: 0,
      processed: 0,
      terminations: 0,
      terminatedBy: null,
      terminatedAt: null,
      terminationStarted: false,
    };

    this.getSuccessLogFile = this.getSuccessLogFile.bind(this);
    this.getFailLogFile = this.getFailLogFile.bind(this);
    this.confirmSubmission = this.confirmSubmission.bind(this);
  }

  get transactionUuid() {
    const { match } = this.props;
    return match.params.uuid;
  }

  componentDidMount() {
    this.getSubmissionStatus();
    this.unmounted = false;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  async getSuccessLogFile() {
    const response = await this.getLogFileUrl();
    return response.downloadUrlForSuccessLog;
  }

  async getFailLogFile() {
    const response = await this.getLogFileUrl();
    return response.downloadUrlForFailLog;
  }

  static isFinished({ total, processed, status }) {
    if (status === 'TERMINATED') return true;
    return total === processed;
  }

  static isTerminationExpired(attributes) {
    if (!attributes) { return false; }

    if (attributes.status === 'TERMINATING') {
      const terminatedAt = moment.utc(moment.unix(attributes.terminatedAt)).local();
      const currentTime = moment().local();
      const diffInMinutes = currentTime.diff(terminatedAt, 'minutes');
      return diffInMinutes >= 1;
    }

    return false;
  }

  async terminateTransactionAgain() {
    const { t, terminateTransaction } = this.props;
    const attributes = {
      id: this.transactionUuid,
      terminatedBy: this.state.initiator,
      terminated: true
    };
    let response;

    this.setState({ loading: true });

    try {
      response = await this.dispatchWithAuth(terminateTransaction, attributes);
      if (response.status === 200) {
        notification.success({
          message: t('transaction:terminateSuccess'),
        });
      }
    } catch ({ err }) {
      if (err.status !== 200) {
        super.handleError(t('transaction:terminateError'), t('common:error'));
      }
    } finally {
      this.setState({ loading: false });
      this.getSubmissionStatus();
    }
  }

  async getLogFileUrl() {
    const { i18n, getBatchLogs } = this.props;
    const currentSelectedLocale = getcurrentlanguage(i18n.languages);

    const response = await this.dispatchWithAuth(
      getBatchLogs,
      this.transactionUuid,
      currentSelectedLocale.key
    );

    return response;
  }

  async terminateInit() {
    const { t, terminateTransaction } = this.props;
    this.setState({ terminationStarted: true, loading: true });
    let response;

    const attributes = {
      id: this.transactionUuid,
      terminatedBy: this.state.initiator,
      terminated: false
    };

    try {
      response = await this.dispatchWithAuth(terminateTransaction, attributes);
      if (response.status === 200) {
        notification.success({
          message: t('transaction:terminateStarted'),
        });
      }
    } catch ({ err }) {
      if (err.status !== 200) {
        super.handleError(t('transaction:terminateError'), t('common:error'));
      }
    } finally {
      this.setState({ loading: false });
      this.getSubmissionStatus();
    }
  }

  dispatchWithAuth(func, ...args) {
    return super.dispatchWithAuth(func, ...args);
  }

  confirmSubmission() {
    const { t } = this.props;
    Modal.confirm({
      width: 530,
      content: t('transaction:terminateConfirmTitle'),
      onOk: async () => {
        this.terminateInit();
      },
      okText: t('common:yes'),
      cancelText: t('common:cancel'),
    });
  }

  async getSubmissionStatus() {
    const { t, getBatchStatus } = this.props;
    let result;

    try {
      result = await this.dispatchWithAuth(
        getBatchStatus,
        this.transactionUuid
      );
    } catch ({ status }) {
      if (status !== 401) {
        super.handleError(
          t(status === 404 ? 'transaction:notFound' : 'transaction:fetchError'),
          t('common:error')
        );
      }
      return;
    }

    const { attributes } = result;
    const status = statusMap[attributes.status];

    const {
      total,
      success,
      fail,
      processed,
      terminations,
      terminatedBy,
      terminatedAt,
      initiatedBy,
      initiatedAt,
      updatedAt
    } = attributes;

    this.setState({
      loading: false,
      error: this.errorResponse(status),
      status,
      total,
      success,
      fail,
      processed,
      terminations,
      terminatedBy,
      terminatedAt,
      initiator: initiatedBy,
      createdAt: initiatedAt,
      updatedAt
    });

    if (TeamTransactionView.isTerminationExpired(attributes)) {
      this.terminateTransactionAgain();
    }

    if (this.stopPolling(status, total, success, fail, processed, terminations)) {
      return;
    }

    this.getSubmissionStatus();
  }

  stopPolling(status, total, success, fail, processed, terminations) {
    if (
      status === ERROR ||
      status === TERMINATED ||
      status === COMPLETE ||
      total === success + fail + terminations ||
      this.unmounted ||
      processed === total
    ) {
      return true;
    }

    return false;
  }

  errorResponse(status) {
    const { t } = this.props;

    if (status === ERROR) {
      return t('transaction:transactionError');
    }

    return null;
  }

  render() {
    const {
      loading,
      error,
      total,
      success,
      fail,
      status,
      processed,
      terminations,
      terminatedBy,
      terminatedAt,
      initiator,
      createdAt,
      updatedAt,
      terminationStarted
    } = this.state;

    const { t } = this.props;
    const isTerminated = status === TERMINATED;
    const isFinished = TeamTransactionView.isFinished({ total, processed, status });

    return (
      <>
        <Row>
          <Col>
            <h1>{`${t('transaction:title')} ${this.transactionUuid}`}</h1>
            <Divider />
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col sm={16} xs={24}>
            <Link to="/teams/batch-process">
              <Button type="primary" icon="upload">{t('teams:newBatchProcess')}</Button>
            </Link>
          </Col>
          {
            !loading && !isTerminated && !isFinished && !terminationStarted && (
              <Col sm={8} xs={24} style={{ textAlign: 'right' }}>
                <Button type="danger" icon="stop" onClick={this.confirmSubmission}>{t('transaction:terminateButton')}</Button>
              </Col>
            )
          }
        </Row>
        <Row>
          <Col>
            <BatchTransactionView
              loading={loading}
              error={error}
              transaction={{
                id: this.transactionUuid,
                initiatedBy: initiator,
                total,
                success,
                fail,
                status,
                processed,
                terminations,
                terminatedBy,
                terminatedAt,
                createdAt,
                updatedAt
              }}
              logFiles={{
                success: this.getSuccessLogFile,
                fail: this.getFailLogFile
              }}
            />
          </Col>
        </Row>
      </>
    );
  }
}

TeamTransactionView.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  getBatchStatus: PropTypes.func.isRequired,
  getBatchLogs: PropTypes.func.isRequired,
  terminateTransaction: PropTypes.func.isRequired,
};

export default withTranslation(['common', 'transaction', 'Teams'])(TeamTransactionView);
