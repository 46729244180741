import Config from '../Config';

export async function api(dispatch, token, params) {
  const {
    query = null,
    endpoint = 'team',
    method = 'GET',
    body = null,
    contentType = 'application/json'
  } = params;

  const fetchConfig = {
    method,
    headers: {
      'content-type': contentType,
      authorization: token
    }
  };

  if (body) {
    if (contentType === 'application/json') {
      fetchConfig.body = JSON.stringify(body);
    } else {
      fetchConfig.body = body;
    }
  }

  let fetchUrl = `${Config.apiGateway.TEAM_API_URL}/${endpoint}`;
  if (query) {
    fetchUrl += query;
  }

  const result = await fetch(
    fetchUrl,
    fetchConfig
  );

  const payload = await result.json();

  if (!result.ok) {
    throw Object.assign(new Error(), {
      status: result.status,
      message: payload.errors
    });
  }

  return payload;
}

export const serialize = (filter = {}, prefix = '?') => {
  const query = [];

  Object.keys(filter).forEach((key) => {
    query.push(`${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`);
  });

  if (query.length === 0) {
    return '';
  }

  return `${prefix}${query.join('&')}`;
};

export function normaliseResponse(item, relationships = []) {
  if (!item) {
    return null;
  }

  let attributes = {};

  if (!item.attributes) {
    attributes.id = item.id;
  } else {
    attributes = {
      ...item.attributes,
      id: item.id
    };
  }

  if (!item.relationships) {
    return attributes;
  }

  relationships.forEach((key) => {
    if (!item.relationships[key]) {
      return;
    }

    const { data } = item.relationships[key];
    attributes[key] = Array.isArray(data) ? (
      data.map((d) => normaliseResponse(d, relationships))
    ) : normaliseResponse(data, relationships);
  });

  return attributes;
}
