import { notification } from 'antd';
import { Base } from 'componentlibrary';

class UpsertTeam extends Base {
  static handleSave(saveFn) {
    return async (team, callbackFn = () => {}) => {
      notification.destroy();
      const ok = await saveFn(team);

      if (ok) callbackFn();
    };
  }

  componentDidCatch(error, info) {
    console.log('Unhandled React js error in componentDidCatch - error:');
    console.log(error);
    console.log('Info:');
    console.log(info);
    this.setState({ hasReactError: true });
  }

  componentWillUnmount() {
    notification.destroy();
  }

  displayErrorMessage(error) {
    const { t } = this.props;
    notification.destroy();
    notification.error({
      message: t('common:error'),
      description: error,
      duration: 0,
    });
  }

  render() {
    return null;
  }
}

export default UpsertTeam;
