import { connect } from 'react-redux';

import TeamExporter from '../../Components/TeamExporter';
import WithDefaults from '../WithDefaults';
import { exportInit, getExportStatus } from '../../reducers/team';

const mapDispatchToProps = (dispatch) => ({
  exportInit(...args) {
    return exportInit(dispatch, ...args);
  },
  getExportStatus(...args) {
    return getExportStatus(dispatch, ...args);
  }
});

export default connect(mapDispatchToProps)(WithDefaults(TeamExporter));
