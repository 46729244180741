import { api, serialize } from './api';

const MEMBER_GET_LIST_ACTION = 'MEMBER_GET_LIST';
const MEMBER_CLEAR_DATA_ACTION = 'MEMBER_CLEAR_DATA';

export function memberDefaultState() {
  return {
    list: {
      data: []
    }
  };
}

export async function getMembers(dispatch, token, filter = {}) {
  const params = {
    endpoint: 'member',
    query: serialize({ ...filter })
  };
  const payload = await api(dispatch, token, params);

  dispatch({
    type: MEMBER_GET_LIST_ACTION,
    payload
  });
}

export function clearMemberData(dispatch) {
  dispatch({
    type: MEMBER_CLEAR_DATA_ACTION
  });
}

export default (state = memberDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case MEMBER_GET_LIST_ACTION:
      return {
        ...state,
        list: {
          data: payload.data ? payload.data.map((item) => item.attributes) : []
        }
      };

    case MEMBER_CLEAR_DATA_ACTION:
      return {
        ...memberDefaultState()
      };

    default:
      return state;
  }
};
