import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  isEmpty, isEqual, isUndefined, debounce
} from 'lodash';
import {
  Input, Select, Button, Row, Col, Form, Switch, Icon, Tag, Spin, AutoComplete, Tooltip, Alert
} from 'antd';

import DACQRCode from '../DACQRCode';
import { CompanySelectorWithData } from '../../Containers/CompanySelector';
import { getOptions, formatOptions, removeDuplicates, formatListOptionsDataForDropdown } from '../../Utils/Selector';
import { statusOptions } from '../../Utils/StatusData';
import { typeOptions } from '../../Utils/TeamTypeData';
import { formatTimestamp } from '../../Utils/TimeUtils';
import { CharacterSetsBasedFiltration } from '../../Utils/CharacterSetsBasedFiltration';

class RawTeamForm extends Component {
  static removeManaged(combinedOwners) {
    return combinedOwners.filter(
      (owner) => !owner || owner.managed === 0 || owner.managed === undefined
    );
  }

  constructor(props) {
    super(props);
    const { currentTeam } = this.props;
    this.state = {
      company: '',
      isOwnerCompany: false,
      hasHierarchicalParents: false,
      hasContractorParents: false,
      teamTypeChanged: false,
      fetching: false,
      selectedType: '',
      memberFilter: {},
      teamFilter: {},
      isChildrenFocused: false,
      isContractorCompanyParent: !isEmpty(currentTeam.parents),
      teamName: currentTeam.name,
      teamContractorCompany: currentTeam.contractorCompanyName,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOwnerChange = this.handleOwnerChange.bind(this);
    this.handleTeamTypeChange = this.handleTeamTypeChange.bind(this);
    this.toggleTeamTypeSelections = this.toggleTeamTypeSelections.bind(this);
    this.clearMemberFilter = this.clearMemberFilter.bind(this);
    this.clearTeamFilter = this.clearTeamFilter.bind(this);
  }

  componentDidMount() {
    const {
      currentTeam: { owner, teamType, company }, isEditTeam
    } = this.props;

    if (teamType && isEditTeam) {
      this.setState({
        selectedType: teamType,
      });
    }

    if (!owner && company) {
      this.setState({
        isOwnerCompany: true,
      });
      this.fetchCompaniesList(company.uuid);
    }

    this.setState({
      company: company && company.uuid ? company.uuid : '',
      hasHierarchicalParents: !!(
        teamType === 'USER' || teamType === 'HIERARCHICAL'
      ),
      hasContractorParents: !!(
        teamType === 'CONTRACTOR_COMPANY' || teamType === 'CONTRACTOR_TEAM'
      ),
    });

    // Team Update. Trigger the teamType change to render the associated
    // owner/parents/children selections
    if (teamType) {
      this.toggleTeamTypeSelections(teamType, false);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { childrenCompanies, form, currentTeam } = this.props;
    const {
      fetching, teamTypeChanged, memberFilter, teamFilter, isChildrenFocused, teamName
    } = this.state;

    if (fetching === false
      && prevState.fetching !== fetching
      && !isEmpty(childrenCompanies)
      && !isChildrenFocused) {
      form.setFieldsValue({
        children:
          isEmpty(currentTeam.children) || teamTypeChanged
            ? []
            : currentTeam.children.map((children) => children.uuid),
      });
    }

    if (!isEqual(prevState.memberFilter, memberFilter) && !isEmpty(memberFilter)) {
      this.fetchDynamicData('members');
    }

    if (!isEqual(prevState.memberFilter, memberFilter) && isEmpty(memberFilter)) {
      this.setState({ fetching: false });
    }

    if (!isEqual(prevState.teamFilter, teamFilter)) {
      this.fetchDynamicData('teams');
    }

    if (!isEqual(prevState.teamFilter, teamFilter) && isEmpty(teamFilter)) {
      this.setState({ fetching: false });
    }

    if (!prevState.isContractorCompanyParent && prevState.teamName !== teamName) {
      form.setFieldsValue({
        contractorCompanyName: teamName,
      });
    }
  }

  async handleOwnerChange(value) {
    const { isOwnerCompany } = this.state;

    if (isOwnerCompany && value) {
      this.fetchCompaniesList(value);
    }

    if (!isOwnerCompany) {
      const {
        members: { data: membersData },
      } = this.props;
      const owner = membersData.filter((user) => user.uuid === value);
      this.setState({
        company: owner[0] && owner[0].company,
      });
    } else {
      this.setState({
        company: value,
      });
    }
  }

  handleTeamParentChange(value) {
    const { selectedType } = this.state;
    if (selectedType === 'CONTRACTOR_COMPANY' || selectedType === 'CONTRACTOR_TEAM') {
      const { form: { setFieldsValue } } = this.props;
      if (value && value.length > 0) {
        let mValue = value;
        if (selectedType === 'CONTRACTOR_TEAM') {
          mValue = value[value.length - 1];
        }
        const selectedCompany = this.filterContractorCompany(mValue);
        if (!selectedCompany) {
          return;
        }
        if (selectedCompany && selectedCompany.superParentName) {
          setFieldsValue({ contractorCompanyName: selectedCompany.superParentName });
        } else {
          setFieldsValue({ contractorCompanyName: selectedCompany.contractorCompanyName });
        }
        this.updateContractorCompanyFieldStatus(true);
      } else {
        setFieldsValue({ contractorCompanyName: null });
        this.updateContractorCompanyFieldStatus(false);
      }
    }
  }

  handleTeamTypeChange(value) {
    const { form } = this.props;

    this.setState({
      selectedType: value,
    });

    form.setFieldsValue({ owner: undefined, contractorCompanyName: undefined, parents: value === 'CONTRACTOR_COMPANY' ? undefined : [] });
    this.toggleTeamTypeSelections(value);
  }

  handleSubmit(e) {
    e.preventDefault();
    const {
      handleSave, closeDrawer, loading, form
    } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        handleSave(this.preparePayload(values), () => closeDrawer());
        loading(true);
      }
    });

    this.clearMemberFilter();
    this.clearTeamFilter();
  }

  handleCancel(e) {
    e.preventDefault();
    const { closeDrawer } = this.props;

    closeDrawer();
    this.clearMemberFilter();
    this.clearTeamFilter();
  }

  onSearch(field, value) {
    const memberField = ['members', 'owner'];
    const teamField = ['parents', 'children'];
    const { selectedType } = this.state;
    let memberFilter = {};
    let teamFilter = {};

    if (memberField.includes(field)) {
      if (value) {
        if (field === 'owner') {
          memberFilter = {
            ...memberFilter,
            'filter.name': value,
            'filter.managed': 0
          };
        } else {
          memberFilter = {
            ...memberFilter,
            'filter.name': value,
          };

          if (this.ownerUuid) {
            memberFilter['filter.owner'] = this.ownerUuid;
          }
        }
      }

      this.setState({ memberFilter });
    }

    if (teamField.includes(field)) {
      if (value) {
        if (field === 'parents') {
          teamFilter = {
            ...teamFilter,
            'filter.name': value,
            // eslint-disable-next-line no-nested-ternary
            'filter.teamType': selectedType === 'HIERARCHICAL' ? 'hierarchical' : selectedType === 'CONTRACTOR_COMPANY' || selectedType === 'CONTRACTOR_TEAM' ? 'contractor_company' : 'hierarchical,contractor_company',
            'filter.status': 'ACTIVE'
          };
        } else {
          teamFilter = {
            ...teamFilter,
            'filter.name': value,
            'filter.status': 'ACTIVE'
          };
        }
      }

      this.setState({ teamFilter });
    }
  }

  get childrenCompanyUuids() {
    const {
      childrenCompanies: { data: childrenCompaniesData },
    } = this.props;

    return childrenCompaniesData.map((company) => company.uuid);
  }

  get dynamicTeams() {
    const { teamFilter } = this.state;
    const { relations } = this.props;
    const data = relations ? relations.data : [];

    return isEmpty(teamFilter) ? [] : data;
  }

  get teamsInCompanyScope() {
    const teams = this.dynamicTeams;
    return teams.filter((team) => this.childrenCompanyUuids.includes(team.company.uuid));
  }

  get childrenTeamTypes() {
    const { selectedType } = this.state;
    const { currentTeam } = this.props;
    const teams = this.teamsInCompanyScope.filter(
      (team) => team.teamType === (selectedType === 'HIERARCHICAL' ? 'USER' : 'CONTRACTOR_TEAM')
    );

    return teams.filter((team) => team.uuid !== currentTeam.uuid);
  }

  get combinedChildrenTeams() {
    const { currentTeam: { children } } = this.props;

    let currentChildren = children;

    if (isUndefined(children)) {
      currentChildren = [];
    }

    return removeDuplicates(formatOptions(currentChildren.concat(this.childrenTeamTypes)));
  }

  get combinedParentTeams() {
    const { currentTeam: { parents } } = this.props;
    const { hasHierarchicalParents } = this.state;

    const teamTypeForFilter = hasHierarchicalParents ? 'HIERARCHICAL' : 'CONTRACTOR_COMPANY';

    const teams = this.dynamicTeams.filter((team) => team.teamType === teamTypeForFilter);

    let currentParents = parents;

    if (isUndefined(parents)) {
      currentParents = [];
    }

    return removeDuplicates(formatOptions(currentParents.concat(teams), true));
  }

  get dynamicMembers() {
    const { memberFilter } = this.state;
    const { members } = this.props;
    const data = members ? members.data : [];

    return isEmpty(memberFilter) ? [] : data;
  }

  get combinedMembers() {
    const { currentTeam: { members } } = this.props;

    let currentMembers = members;

    if (isUndefined(members)) {
      currentMembers = [];
    }

    return removeDuplicates(formatOptions(currentMembers.concat(this.dynamicMembers)));
  }

  get contractorTypeOptions() {
    const { appConfig: { contractorTypeOptions } } = this.props;
    const currentContractorType = [];
    return removeDuplicates(formatListOptionsDataForDropdown(currentContractorType.concat(contractorTypeOptions), 'teamContractorType'));
  }

  get combinedOwners() {
    const { currentTeam: { owner } } = this.props;

    let currentOwner = [owner];

    if (isUndefined(owner) || owner === '') {
      currentOwner = [];
    }

    const unmanagedOwners = RawTeamForm.removeManaged(currentOwner.concat(this.dynamicMembers));
    return removeDuplicates(formatOptions(unmanagedOwners));
  }

  get ownerUuid() {
    const { currentTeam, form } = this.props;

    if (currentTeam.owner) {
      return currentTeam.owner.uuid;
    }

    return form.getFieldValue('owner');
  }

  get latestDac() {
    const { currentTeam } = this.props;

    if (currentTeam.dacs && currentTeam.dacs.length > 0) {
      return currentTeam.dacs[0];
    }

    return null;
  }

  getOptions(options) {
    const { t } = this.props;
    if (options) {
      options.sort((a, b) => (a.text > b.text ? 1 : -1));
    }
    return getOptions(options, t);
  }

  getParents(teams) {
    let allTeams = [];
    const { hasHierarchicalParents, hasContractorParents } = this.state;

    if (hasHierarchicalParents) {
      allTeams = teams.filter((team) => team.teamType === 'HIERARCHICAL');
    }
    if (hasContractorParents) {
      allTeams = teams.filter((team) => team.teamType === 'CONTRACTOR_COMPANY');
    }

    return allTeams;
  }

  async getIdToken() {
    const {
      auth: { getIdToken },
    } = this.props;

    return getIdToken();
  }

  updateContractorCompanyFieldStatus(disable) {
    const { selectedType } = this.state;
    if (selectedType === 'CONTRACTOR_COMPANY' || selectedType === 'CONTRACTOR_TEAM') {
      this.setState({
        isContractorCompanyParent: disable,
      });
    }
  }

  filterContractorCompany(value) {
    const selectedCompany = this.dynamicTeams.filter((item) => item.uuid === value);
    if (selectedCompany.length > 0) {
      return selectedCompany[0];
    }
    return null;
  }

  childrenOnBlur() {
    this.setState({ isChildrenFocused: false });
    this.clearTeamFilter();
  }

  async fetchCompaniesList(companyUuid) {
    const { getChildrenCompanies } = this.props;

    const token = await this.getIdToken();

    this.setState({
      fetching: true,
    });
    getChildrenCompanies(token, companyUuid).then(() => this.setState({
      fetching: false,
    }));
  }

  clearMemberFilter() {
    this.setState({ memberFilter: {} });
  }

  clearTeamFilter() {
    this.setState({ teamFilter: {} });
  }

  isTeamMemberOrOwner(team) {
    const { auth: { user } } = this.props;
    const teamMembers = team.members || [];

    return teamMembers.map(
      (member) => member.uuid
    ).includes(user.uuid) || team.owner.uuid === user.uuid;
  }

  async fetchDynamicData(key) {
    const { getMembers, getRelationalTeams } = this.props;
    const { memberFilter, teamFilter } = this.state;

    const token = await this.getIdToken();

    try {
      this.setState({ fetching: true });
      if (key === 'members') {
        await getMembers(token, memberFilter);
      } else if (key === 'teams') {
        await getRelationalTeams(token, teamFilter);
      }
    } catch (err) {
      if (err.status !== 401) {
        console.error(err);
      }
    } finally {
      this.setState({ fetching: false });
    }
  }

  toggleTeamTypeSelections(teamType, teamTypeChanged = true) {
    const companyOwnerTeam = ['HIERARCHICAL', 'CONTRACTOR_COMPANY'];
    if (companyOwnerTeam.includes(teamType)) {
      this.setState({
        teamTypeChanged,
        isOwnerCompany: true,
        hasHierarchicalParents: teamType === 'HIERARCHICAL',
        hasContractorParents: teamType === 'CONTRACTOR_COMPANY',
      });
    } else {
      this.setState({
        teamTypeChanged,
        isOwnerCompany: false,
        hasHierarchicalParents: teamType === 'USER',
        hasContractorParents: teamType === 'CONTRACTOR_TEAM',
      });
    }
  }

  preparePayload(formValues) {
    const { company, isOwnerCompany } = this.state;
    const { currentTeam, isEditTeam } = this.props;
    const { uuid } = currentTeam;
    const doPatch = Boolean(currentTeam.name);
    const teamWithParents = ['USER', 'CONTRACTOR_TEAM'];
    const teamWithChildren = [];

    let payload = {};

    const formValuesWithCompany = {
      ...formValues,
      company,
    };

    if (doPatch) {
      payload = { uuid, ...formValuesWithCompany };
    } else {
      payload = {
        ...currentTeam,
        ...formValuesWithCompany,
      };
    }

    // Convert 'payload.parents' to an array if it's a string, otherwise keep it unchanged.
    payload.parents = typeof payload.parents === 'string' ? payload.parents.split(',') : payload.parents;
    payload.name = payload.name.trim();

    if (payload.contractorCompanyName) {
      payload.contractorCompanyName = payload.contractorCompanyName.trim();
    }

    if (isEmpty(payload.members)) {
      if (isEditTeam) {
        payload.members = null;
      } else {
        delete payload.members;
      }
    }

    if (typeof (payload.parents) === 'string') {
      payload.parents = [payload.parents];
    }
    if (isEmpty(payload.parents)) {
      if (isEditTeam) {
        payload.parents = null;
      } else {
        delete payload.parents;
      }
    }

    if (isEmpty(payload.children)) {
      if (isEditTeam) {
        payload.children = null;
      } else {
        delete payload.children;
      }
    }

    if (teamWithParents.includes(payload.teamType) && isEditTeam) {
      delete payload.children;
    }

    if (teamWithChildren.includes(payload.teamType) && isEditTeam) {
      delete payload.parents;
    }

    if (!payload.contractorCompanyName) {
      payload.contractorCompanyName = null;
    }

    if (isOwnerCompany) {
      delete payload.owner;
    }

    return payload;
  }

  render() {
    const {
      t, currentTeam, contractorCompanies, form, isEditTeam, auth, crmServiceEnabled, appConfig
    } = this.props;

    const { user } = auth || {};
    const { data: contractorCompaniesData } = contractorCompanies || {};
    const { getFieldDecorator, getFieldValue } = form;
    const {
      isOwnerCompany,
      teamTypeChanged,
      fetching,
      selectedType,
      teamFilter,
      teamContractorCompany,
    } = this.state;
    const contractorTeams = ['CONTRACTOR_COMPANY', 'CONTRACTOR_TEAM'];
    const teamDac = this.latestDac;

    // Check if 'selectedType' is valid, excluding an empty string or a specific translation value.
    const isValidType = !(selectedType === '' || selectedType === t('teamsForm:teamType.value.select'));

    // This object maps different values of 'selectedType' to corresponding translation keys.
    // It's used to determine the appropriate label for the team's parent based on its type.
    const typeToTeamParentMap = {
      CONTRACTOR_COMPANY: t('parentCompanyTeam.name'),
      HIERARCHICAL: t('parentCompanyTeam.name'),
      CONTRACTOR_TEAM: t('parentCompany.name'),
      USER: t('teamParents.name'),
    };

    const typeToTeamParentTooltipMap = {
      CONTRACTOR_COMPANY: t('teams:contractorCompanyTooltip'),
      HIERARCHICAL: t('teams:UserTooltip'),
      CONTRACTOR_TEAM: t('teams:contractorCompanyTooltip'),
      USER: t('teams:UserTooltip'),
    };

    // Set 'teamParentLabel' based on 'selectedType' or an empty string if not found.
    const teamParentLabel = typeToTeamParentMap[selectedType] || '';
    const teamParentTooltip = typeToTeamParentTooltipMap[selectedType] || '';
    const isTeamStatusBlocked = getFieldValue('status') === 'BLOCKED';
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item label={t('teamType.name')}>
          {getFieldDecorator('teamType', {
            initialValue: isEditTeam === true ? currentTeam.teamType : 'SELECT',
            rules: [
              {
                required: true,
                message: t('teamType.validation.required'),
              },
            ],
          })(
            <Select
              disabled={isEditTeam}
              showSearch
              onChange={this.handleTeamTypeChange}
            >
              <Select.Option value="SELECT" key="SELECT">
                {t('teamsForm:teamType.value.select')}
              </Select.Option>
              {this.getOptions(typeOptions)}
            </Select>
          )}
        </Form.Item>
        {isValidType && (
          <>
            {isOwnerCompany &&
              (
                <Form.Item
                  label={(
                    <span>
                      {t('teams:teamFilter.companyStructure')}
                      <Tooltip title="Select the company structure level this team should be assigned to">
                        <Icon type="info-circle" style={{ marginLeft: 5 }} />
                      </Tooltip>
                    </span>
                  )}
                >
                  {getFieldDecorator('owner', {
                    initialValue: currentTeam && currentTeam.company
                      ? currentTeam.company.uuid
                      : null,
                    rules: [
                      {
                        required: true,
                        message: t('companyStructure.validation.required'),
                      },
                    ],
                  })(
                    <CompanySelectorWithData
                      placeholder={t('searchPlaceHolder.company')}
                      onChange={this.handleOwnerChange}
                    />
                  )}
                </Form.Item>
              )}
            <Form.Item
              label={(
                <span>
                  {teamParentLabel}
                  <Tooltip title={teamParentTooltip}>
                    <Icon type="info-circle" style={{ marginLeft: 5 }} />
                  </Tooltip>
                </span>
              )}
            >
              {getFieldDecorator('parents', {
                initialValue:
                  isEmpty(currentTeam.parents) || teamTypeChanged
                    ? []
                    : currentTeam.parents.map((parent) => parent.uuid),
              })(
                <Select
                  allowClear
                  showSearch
                  placeholder={t('searchPlaceHolder.team')}
                  onSearch={debounce((value) => this.onSearch('parents', value), 800)}
                  onBlur={() => this.clearTeamFilter()}
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  mode={selectedType === 'HIERARCHICAL' || selectedType === 'CONTRACTOR_COMPANY' ? 'single' : 'multiple'}
                  optionFilterProp="children"
                  filterOption="true"
                  onChange={(value) => this.handleTeamParentChange(value)}
                >
                  {this.getOptions(this.combinedParentTeams)}
                </Select>
              )}
            </Form.Item>
            {isOwnerCompany && (
              <Form.Item label={t('teamChildren.name')}>
                {getFieldDecorator('children', {
                  initialValue: [],
                })(
                  <Select
                    allowClear
                    showSearch
                    placeholder={!fetching && t('searchPlaceHolder.team')}
                    onSearch={debounce((value) => this.onSearch('children', value), 800)}
                    onFocus={() => this.setState({ isChildrenFocused: true })}
                    onBlur={() => this.childrenOnBlur()}
                    loading={fetching && isEmpty(teamFilter)}
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    mode="multiple"
                    disabled
                    optionFilterProp="children"
                  >
                    {this.getOptions(this.combinedChildrenTeams)}
                  </Select>
                )}
              </Form.Item>
            )}
            <Form.Item label={t('teamName.name')}>
              {getFieldDecorator('name', {
                initialValue: currentTeam.name,
                rules: [
                  {
                    transform: (value) => {
                      const trimmedValue = value && value.trim();
                      this.setState({ teamName: trimmedValue });
                      return trimmedValue;
                    },
                    required: true,
                    message: t('teamName.validation.required'),
                  },
                ],
              })(<Input />)}
            </Form.Item>
            {contractorTeams.includes(selectedType) && (
              <Form.Item label={t('teamContractorCompany.name')}>
                {getFieldDecorator('contractorCompanyName', {
                  initialValue: currentTeam.superParent == null ?
                    currentTeam.contractorCompanyName : teamContractorCompany,
                  rules: [
                    {
                      transform: (value) => value && value.trim(),
                      message: t('teamContractorCompany.validation.required'),
                    },
                  ],
                })(
                  <AutoComplete
                    filterOption={(inputValue, option) =>
                      CharacterSetsBasedFiltration(inputValue, option,
                        appConfig.supportedCharacterSets)}
                    dataSource={contractorCompaniesData}
                    disabled
                  />
                )}
              </Form.Item>
            )}

            {!isOwnerCompany &&
              (
                <Form.Item label={t('teamOwner.name')}>
                  {getFieldDecorator('owner', {
                    initialValue: currentTeam && currentTeam.owner
                      ? currentTeam.owner.uuid
                      : null,
                    rules: [
                      {
                        required: true,
                        message: t('teamOwner.validation.required'),
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      onSearch={debounce((value) => this.onSearch('owner', value), 800)}
                      onBlur={() => this.clearMemberFilter()}
                      placeholder={t('searchPlaceHolder.user')}
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      optionFilterProp="children"
                      onChange={this.handleOwnerChange}
                    >
                      {this.getOptions(this.combinedOwners)}
                    </Select>
                  )}
                </Form.Item>
              )}

            {!isOwnerCompany && (
              <Form.Item label={t('teamMembers.name')}>
                {getFieldDecorator('members', {
                  initialValue: isEmpty(currentTeam.members)
                    ? []
                    : currentTeam.members.map((member) => member.uuid),
                  rules: [
                    {
                      required: false
                    },
                  ],
                })(
                  <Select
                    allowClear
                    showSearch
                    onSearch={debounce((value) => this.onSearch('members', value), 800)}
                    onBlur={() => this.clearMemberFilter()}
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    placeholder={t('searchPlaceHolder.user')}
                    mode="multiple"
                    optionFilterProp="children"
                  >
                    {this.getOptions(this.combinedMembers)}
                  </Select>
                )}
              </Form.Item>
            )}
            {appConfig.enableContractorType && selectedType === 'CONTRACTOR_COMPANY' && (
              <Form.Item label={t('teamContractorType.name')}>
                {getFieldDecorator('contractorType', {
                  initialValue: currentTeam.contractorType || '',
                })(
                  <Select>
                    {this.getOptions(this.contractorTypeOptions)}
                  </Select>
                )}
              </Form.Item>
            )}

            <Form.Item label={t('teamStatus.name')}>
              {getFieldDecorator('status', {
                initialValue: currentTeam.status || 'ACTIVE',
                rules: [
                  {
                    required: true,
                    message: t('teamStatus.validation.required'),
                  },
                ],
              })(<Select>{this.getOptions(statusOptions)}</Select>)}
            </Form.Item>
            {(selectedType === 'CONTRACTOR_COMPANY' || selectedType === 'HIERARCHICAL') &&
              isTeamStatusBlocked &&
                <Alert message={t('teamStatus.message')} type="warning" showIcon style={{ marginBottom: 24 }} />}
            {teamDac && (
              <Row>
                <Col sm={24} lg={12}>
                  <Form.Item label={t('existingTeamDeviceCode.name')}>
                    {
                      this.isTeamMemberOrOwner(currentTeam) && (
                        <div>
                          <DACQRCode
                            dac={teamDac.code}
                            username={user && user.username}
                            size={120}
                          />
                        </div>
                      )
                    }
                    <div>
                      <Tag>{teamDac.code}</Tag>
                    </div>
                  </Form.Item>
                </Col>
                {
                  teamDac.createdAt && (
                    <Col sm={24} lg={12}>
                      <Form.Item label={t('existingTeamDeviceCodeCreatedAt.name')}>
                        <div>
                          {formatTimestamp(teamDac.createdAt)}
                        </div>
                      </Form.Item>
                    </Col>
                  )
                }
              </Row>
            )}
            {(!isOwnerCompany && crmServiceEnabled) && (
              <Form.Item label={t('newTeamDeviceCode.name')}>
                {getFieldDecorator('newDac', {
                  initialValue: false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                  />
                )}
              </Form.Item>
            )}
            <Row>
              <Col sm={24} lg={24} style={{ textAlign: 'left' }}>
                <Button type="primary" htmlType="submit" disabled={fetching}>
                  {t('common:save')}
                </Button>
                <Button onClick={this.handleCancel} style={{ marginLeft: 8 }}>
                  {t('common:cancel')}
                </Button>
              </Col>
            </Row>
          </>
        )}
        {!isValidType && (
          <Row>
            <Col sm={24} lg={24} style={{ textAlign: 'left' }}>
              <Button onClick={this.handleCancel}>
                {t('common:cancel')}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    );
  }
}

RawTeamForm.propTypes = {
  currentTeam: PropTypes.object,
  relations: PropTypes.object.isRequired,
  childrenCompanies: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  members: PropTypes.object.isRequired,
  contractorCompanies: PropTypes.object.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  getChildrenCompanies: PropTypes.func.isRequired,
  getMembers: PropTypes.func.isRequired,
  getRelationalTeams: PropTypes.func.isRequired,
  form: PropTypes.object,
  loading: PropTypes.func.isRequired,
  isEditTeam: PropTypes.bool.isRequired,
  crmServiceEnabled: PropTypes.bool.isRequired,
  appConfig: PropTypes.object
};

RawTeamForm.defaultProps = {
  currentTeam: {},
  form: {},
  appConfig: {}
};

export const TeamForm = Form.create()(
  withTranslation(['teamsForm', 'teams'])(RawTeamForm)
);
