export const typeOptions = [
  {
    key: 'USER',
    text: 'teamsForm:teamType.value.user',
    value: 'USER',
  },
  {
    key: 'HIERARCHICAL',
    text: 'teamsForm:teamType.value.hierarchical',
    value: 'HIERARCHICAL',
  },
  {
    key: 'CONTRACTOR COMPANY',
    text: 'teamsForm:teamType.value.contractorCompany',
    value: 'CONTRACTOR_COMPANY',
  },
  {
    key: 'CONTRACTOR TEAM',
    text: 'teamsForm:teamType.value.contractorTeam',
    value: 'CONTRACTOR_TEAM',
  },
];
