import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { Drawer } from 'antd';
import NewTeam from '../../Containers/NewTeam';
import TeamUpdate from '../../Containers/TeamUpdate';

const TeamDrawer = ({
  teams,
  isDrawerVisible,
  closeDrawer,
  currentTeam,
  loading,
  companies,
  fetchList,
  t,
  isMobile,
}) => {
  const isEditTeam = Boolean(currentTeam.name);

  return (
    <Drawer
      title={isEditTeam ? t('updateTeam') : t('createTeam')}
      placement="right"
      visible={isDrawerVisible}
      closable={false}
      maskClosable={false}
      width={isMobile ? '100%' : '40%'}
      headerStyle={{ height: '7%' }}
      bodyStyle={{ overflow: 'auto', height: '93%' }}
      destroyOnClose
      onClose={closeDrawer}
    >
      {isEditTeam ? (
        <TeamUpdate
          loading={loading}
          currentTeam={currentTeam}
          closeDrawer={closeDrawer}
          teams={teams}
          isEditTeam={isEditTeam}
          companies={companies}
          fetchList={fetchList}
        />
      ) : (
        <NewTeam
          loading={loading}
          currentTeam={currentTeam}
          closeDrawer={closeDrawer}
          teams={teams}
          isEditTeam={isEditTeam}
          companies={companies}
        />
      )}
    </Drawer>
  );
};

TeamDrawer.propTypes = {
  t: PropTypes.func.isRequired,
  teams: PropTypes.array.isRequired,
  isDrawerVisible: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  loading: PropTypes.func.isRequired,
  currentTeam: PropTypes.object.isRequired,
  companies: PropTypes.object.isRequired,
  fetchList: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default withTranslation(['teams'])(TeamDrawer);
