import { connect } from 'react-redux';

import { getRiskProfile, saveRiskProfile, clearRiskProfileData } from '../../reducers/riskProfile';
import { getTeam, clearSelectedTeam } from '../../reducers/team';
import { getTasks, clearTaskData } from '../../reducers/task';
import { getRisks, clearRiskData } from '../../reducers/risk';

import RiskProfile from '../../Components/RiskProfile';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  riskProfile: state.riskProfile.item,
  team: state.team.selected,
  tasks: state.task,
  risks: state.risk,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getRiskProfile(...args) {
    return getRiskProfile(dispatch, ...args);
  },
  saveRiskProfile(...args) {
    return saveRiskProfile(dispatch, ...args);
  },
  getTeam(...args) {
    return getTeam(dispatch, ...args);
  },
  getTasks(...args) {
    return getTasks(dispatch, ...args);
  },
  getRisks(...args) {
    return getRisks(dispatch, ...args);
  },
  clearRiskData(...args) {
    return clearRiskData(dispatch, ...args);
  },
  clearTaskData(...args) {
    return clearTaskData(dispatch, ...args);
  },
  clearRiskProfileData(...args) {
    return clearRiskProfileData(dispatch, ...args);
  },
  clearSelectedTeam(...args) {
    return clearSelectedTeam(dispatch, ...args);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(RiskProfile));
