import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Row, Col, Spin, Divider, Button, Tag, Typography
} from 'antd';
import { Base, ResponsiveTable, Empty } from 'componentlibrary';
import { formatTimestamp } from '../../Utils/TimeUtils';
import { statusMap } from '../../Utils/BatchTransactionStatus';
import styles from './styles.scss';

const { Title } = Typography;

export class BatchTransactionList extends Base {
  static getTransactionStatus(item, t) {
    if (item.status in statusMap) {
      return t(`${statusMap[item.status]}`);
    }
    return undefined;
  }

  static renderTag(item, color) {
    return (
      <Tag color={item > 0 && color}>{ item }</Tag>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.loading = this.loading.bind(this);
  }

  componentDidMount() {
    this.fetchList();
  }

  loading(state) {
    this.setState({ loading: state });
  }

  get tableHeader() {
    const { t } = this.props;
    const headerItem = (key, label, width) => ({
      key,
      label,
      width,
    });

    return [
      headerItem('initiatedBy', t('batchTransaction.initiatedBy'), '20%'),
      headerItem('initiatedAt', t('batchTransaction.initiatedAt'), '30%'),
      headerItem('updatedAt', t('batchTransaction.updatedAt'), '30%'),
      headerItem('status', t('status'), '20%'),
      headerItem('success', t('batchTransaction.success'), '10%'),
      headerItem('fail', t('batchTransaction.fail'), '10%'),
      headerItem('total', t('batchTransaction.total'), '10%'),
      headerItem('actions', '', '15%'),
    ];
  }

  async fetchList() {
    const { t, getBatchTransactions } = this.props;

    try {
      this.loading(true);
      await super.dispatchWithAuth(getBatchTransactions);
      super.onDataRetrieved();
    } catch (err) {
      // Unauthorized errors are already handled in the reducer
      if (err.status !== 401) {
        console.error(err);
        super.handleError(t('transaction:fetchError'));
      }
    } finally {
      this.loading(false);
    }
  }

  transformDataForDisplay(data) {
    return (data || []).map((_item) => {
      const item = { ..._item };
      item.initiatedAt = formatTimestamp(item.initiatedAt, 'L LTS');
      item.updatedAt = formatTimestamp(item.updatedAt, 'L LTS');
      item.status = BatchTransactionList.getTransactionStatus(item, this.props.t);
      item.success = BatchTransactionList.renderTag(item.success, 'green');
      item.fail = BatchTransactionList.renderTag(item.fail, 'volcano');
      item.actions = this.renderActions(_item);
      return item;
    });
  }

  renderActions(item) {
    const { t } = this.props;
    return (
      <Col style={{ marginTop: '2px' }}>
        <Link
          title={t('transactionView')}
          to={`/teams/transaction/${item.id}`}
        >
          <Button
            icon="eye"
            title={t('view')}
          />
        </Link>
      </Col>
    );
  }

  render() {
    const {
      t, transactions
    } = this.props;
    const {
      loading
    } = this.state;

    return (
      <Media query="(max-width: 769px)">
        {() => (
          <>
            <Row type="flex" justify="center" align="top">
              <Col span={24}>
                <Title level={4} className={styles.title}>
                  {t('transactionsList')}
                </Title>
                <Divider />
              </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <Col>
                <Link to="/teams/batch-process">
                  <Button
                    type="primary"
                    icon="user-add"
                  >
                    {t('newBatchProcess')}
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Spin
                  spinning={loading}
                  size="large"
                  style={{
                    marginTop: 40,
                    position: 'fixed',
                    top: '20%',
                    bottom: '20%'
                  }}
                >
                  <ResponsiveTable
                    header={this.tableHeader}
                    data={this.transformDataForDisplay(transactions)}
                  />
                  {!loading && transactions && transactions.length === 0 && (
                    <Empty message={t('common:noRecordsFound')} />
                  )}
                </Spin>
              </Col>
            </Row>
          </>
        )}
      </Media>
    );
  }
}

BatchTransactionList.defaultProps = {
  list: [],
};

BatchTransactionList.propTypes = {
  t: PropTypes.func.isRequired,
  list: PropTypes.array,
};

export default withTranslation(['teams', 'common'])(BatchTransactionList);
