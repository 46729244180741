import { api, normaliseResponse, serialize } from './api';

const TASK_GET_LIST_ACTION = 'TASK_GET_LIST';
const TASK_CLEAR_DATA_ACTION = 'TASK_CLEAR_DATA';

const taskRelationships = ['risk', 'control'];

export function taskDefaultState() {
  return {
    list: [],
    hash: {}
  };
}

export async function getTasks(dispatch, token, companyUuid, filter = {}) {
  const params = {
    endpoint: `task/${companyUuid}`,
    ...(filter && { query: serialize(filter) })
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: TASK_GET_LIST_ACTION,
    payload
  });
}

export function clearTaskData(dispatch) {
  dispatch({
    type: TASK_CLEAR_DATA_ACTION
  });
}

// The data from the Task API is a flat list of repeatable objects.
// Our UI needs to transform this into a more structured object to display as
// tabular data with nested relationships
export function transformTaskData(data) {
  const hash = {};
  const risks = {};
  const list = [];

  // map tasks and risks by IDs
  data.forEach((task) => {
    if (!hash[task.id]) {
      hash[task.id] = {
        id: task.id,
        name: task.name,
        risks: []
      };
    }

    if (!risks[task.risk.id]) {
      risks[task.risk.id] = {
        taskId: task.id,
        control: []
      };
    }

    hash[task.id].risks.push(task.risk);
    risks[task.risk.id].control.push(task.control);
  });

  // convert maps to arrays
  Object.keys(hash).forEach((key) => {
    const task = hash[key];

    // de-duplicate risks
    task.risks = task.risks.filter(
      (risk, index, array) => array.findIndex((r) => r.id === risk.id || !risk.name) === index
    );

    // de-duplicate control
    task.risks.forEach((risk, i) => {
      task.risks[i].control = risks[risk.id].control.filter(
        (control, index, array) => array.findIndex(
          (c) => c.name === control.name || !control.name
        ) === index
      );
    });

    list.push(task);
  });

  return { list, hash };
}

export default (state = taskDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case TASK_GET_LIST_ACTION: {
      const data = payload && payload.data;
      const { list, hash } = transformTaskData(
        data.map((item) => normaliseResponse(item, taskRelationships))
      );
      return {
        ...state,
        list,
        hash,
      };
    }

    case TASK_CLEAR_DATA_ACTION:
      return {
        ...taskDefaultState()
      };

    default:
      return state;
  }
};
