import { api, normaliseResponse, serialize } from './api';

const RISK_GET_LIST_ACTION = 'RISK_GET_LIST';
const RISK_CLEAR_DATA_ACTION = 'RISK_CLEAR_DATA';

const riskRelationships = ['risk', 'control'];

export function riskDefaultState() {
  return {
    list: [],
    hash: {}
  };
}

export async function getRisks(dispatch, token, companyUuid, filter = {}) {
  const params = {
    endpoint: `risk/${companyUuid}`,
    ...(filter && { query: serialize(filter) })
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: RISK_GET_LIST_ACTION,
    payload
  });
}

export function clearRiskData(dispatch) {
  dispatch({
    type: RISK_CLEAR_DATA_ACTION
  });
}

export default (state = riskDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case RISK_GET_LIST_ACTION: {
      const data = payload && payload.data;
      const list = data ? data.map((item) => normaliseResponse(item, riskRelationships)) : [];
      const hash = {};
      list.forEach((item) => {
        hash[item.id] = item;
      });

      return {
        ...state,
        list,
        hash,
      };
    }

    case RISK_CLEAR_DATA_ACTION:
      return {
        ...riskDefaultState()
      };

    default:
      return state;
  }
};
