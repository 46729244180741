import React from 'react';
import QRCode from 'react-qr-code';
import PropTypes from 'prop-types';

export const activationUrl = (dac, username) => `forwoodapp://?dac=${dac}&login_name=${username}`;

const DACQRCode = ({
  size,
  dac,
  username,
}) => (
  <QRCode
    title="DAC QR Code"
    value={activationUrl(dac, username)}
    size={size}
  />
);

DACQRCode.propTypes = {
  size: PropTypes.number.isRequired,
  dac: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default DACQRCode;
