import { combineReducers } from 'redux';
import { appConfig } from 'componentlibrary';
import auth from './auth';
import team from './team';
import member from './member';
import riskProfile from './riskProfile';
import task from './task';
import risk from './risk';
import company from './company';
import site from './site';

const appReducer = combineReducers({
  appConfig,
  auth,
  team,
  member,
  riskProfile,
  task,
  risk,
  company,
  site
});

export default (state, action) => appReducer(state, action);
