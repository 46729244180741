import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json'; // english
import fr from './fr.json'; // french
import es from './es.json'; // spanish
import mn from './mn.json'; // mongolian
import pt from './pt.json'; // portuguese (brazil)
import ru from './ru.json'; // russian
import is from './is.json'; // icelandic
import nb from './nb.json'; // norwegian (bokmål)
import zh from './zh.json'; // chinese (simplified)
import id from './id.json'; // indonesian
import tpi from './tpi.json'; // tok Pisin
import nl from './nl.json'; // dutch
import de from './de.json'; // german
import it from './it.json'; // italian
import sr from './sr.json'; // serbian
import fi from './fi.json'; // finnish
import ja from './ja.json'; // japanese
import pl from './pl.json'; // polish
import hi from './hi.json'; // hindi
import uk from './uk.json'; // ukrainian
import el from './el.json'; // greek
import tr from './tr.json'; // turkish

export const SELECT_LOCALE = 'i18nextLng';

function initialiseI18nModule() {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: {
        default: ['en']
      },
      debug: false,
      interpolation: {
        escapeValue: false // not needed for react!!
      },
      detection: {
      // order and from where user language should be detected
        order: ['localStorage', 'navigator', 'querystring', 'cookie', 'htmlTag', 'path', 'subdomain'],
        lookupLocalStorage: SELECT_LOCALE,
        caches: ['localStorage']
      },
      // lng: 'es', // If you want to enforce a default language
      // react i18next special options (optional)
      react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
      },
      resources: {
      // add all resource languages here
        en,
        fr,
        es,
        mn,
        pt,
        ru,
        is,
        nb,
        zh,
        id,
        tpi,
        nl,
        de,
        it,
        sr,
        fi,
        ja,
        pl,
        hi,
        uk,
        el,
        tr,
      }
    }).on('languageChanged', (lang) => {
      document.documentElement.lang = lang;
    });
}

export function clearLocaleSelection() {
  localStorage.removeItem(SELECT_LOCALE);
  initialiseI18nModule();
}

initialiseI18nModule();

export default i18n;
