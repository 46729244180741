import * as React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  Base,
  ValidationStep,
  SUBMISSION_STEP,
  VALIDATION_STEP,
  VALIDATION_INITIALSING,
  VALIDATION_FILE_FORMAT_FAIL,
  VALIDATION_PASS,
  VALIDATION_FAIL,
  VALIDATION_UNEXPECTED_ERROR
} from 'componentlibrary';

const completedValidationStatuses = [
  VALIDATION_PASS,
  VALIDATION_FILE_FORMAT_FAIL,
  VALIDATION_FAIL,
  VALIDATION_UNEXPECTED_ERROR
];

const errorMessageStatuses = [
  VALIDATION_FILE_FORMAT_FAIL,
  VALIDATION_UNEXPECTED_ERROR
];

const statusMap = {
  VALIDATION_INITIATED: VALIDATION_INITIALSING
};

export class BatchTeamValidationStep extends Base {
  static getValidationDetail(result) {
    const { status, error } = result;
    if (errorMessageStatuses.includes(status)) {
      return error;
    }

    if (status === VALIDATION_PASS) {
      return result.s3_key;
    }

    return result.file_url;
  }

  constructor() {
    super();

    this.state = {
      validationState: VALIDATION_INITIALSING,
      validationDetail: null
    };

    this.onSubmission = this.onSubmission.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { batchState } = this.props;
    const { uploadRequestResponse } = batchState;

    if (!prevProps.batchState.uploadRequestResponse && uploadRequestResponse) {
      this.setState({
        validationState: VALIDATION_INITIALSING
      });

      const { attributes } = uploadRequestResponse;
      this.getValidationStatus(attributes.uuid);
    }
  }

  onSubmission() {
    const { handleStepChange } = this.props;
    const { validationDetail } = this.state;
    handleStepChange(SUBMISSION_STEP, validationDetail);
  }

  async getValidationStatus(transactionId) {
    const { t, getValidationStatus } = this.props;
    let result;

    try {
      result = await getValidationStatus(transactionId);
    } catch ({ status }) {
      if (status !== 401) {
        super.handleError(t('unexpectedErrorValidation'));
      }
      return;
    }

    const { attributes } = result;
    const status = statusMap[attributes.status] || attributes.status;

    this.setState({
      validationState: status,
      validationDetail: BatchTeamValidationStep.getValidationDetail(attributes)
    });

    if (completedValidationStatuses.includes(status)) {
      return;
    }

    this.getValidationStatus(transactionId);
  }

  render() {
    const { batchStep, batchState, handleStepChange } = this.props;
    const { validationState, validationDetail } = this.state;

    if (batchStep !== VALIDATION_STEP || !batchState) {
      return null;
    }

    return (
      <ValidationStep
        batchStep={batchStep}
        batchState={batchState}
        handleStepChange={handleStepChange}
        validationState={validationState}
        validationDetail={validationDetail}
        onSubmission={this.onSubmission}
      />
    );
  }
}

BatchTeamValidationStep.propTypes = {
  batchStep: PropTypes.any.isRequired,
  batchState: PropTypes.object.isRequired,
  handleStepChange: PropTypes.func.isRequired,
  getValidationStatus: PropTypes.func.isRequired
};

export default withTranslation(['batchProcess'])(BatchTeamValidationStep);
