import React from 'react';
import PropTypes from 'prop-types';
import { TreeSelect } from 'antd';
import { withTranslation } from 'react-i18next';

export class RawTreeSelector extends React.PureComponent {
  static filterNodes(input, option) {
    return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  constructor(props) {
    super(props);
    this.renderTreeNode = this.renderTreeNode.bind(this);
    this.getPropForTreeSelect = this.getPropForTreeSelect.bind(this);
  }

  getPropForTreeSelect() {
    const {
      treeDefaultExpandAll,
      multiple,
      disabled,
      value,
      style,
      placeholder,
      onChange,
      className,
    } = this.props;

    const treeSelectProp = {
      className,
      allowClear: true,
      showSearch: true,
      filterTreeNode: RawTreeSelector.filterNodes,
      treeDefaultExpandAll,
      dropdownStyle: { maxHeight: 400, overflow: 'auto' },
      onChange,
      disabled,
      multiple,
      labelInValue: false,
    };

    if (value !== undefined) {
      treeSelectProp.value = value;
    }

    if (placeholder !== undefined) {
      treeSelectProp.placeholder = placeholder;
    }

    if (placeholder !== style) {
      treeSelectProp.style = style;
    }
    return treeSelectProp;
  }

  renderTreeNode(node) {
    if (node === null) {
      return <></>;
    }
    const {
      uuid, name, children, status
    } = node;
    const { onlyShowActive } = this.props;
    const childrenNode = [];

    if (onlyShowActive && status !== 'ACTIVE') {
      return null;
    }

    for (let i = 0; i < children.length; i++) {
      if (onlyShowActive && children[i].status !== 'ACTIVE') {
        return null;
      }
      childrenNode.push(this.renderTreeNode(children[i]));
    }

    return (
      <TreeSelect.TreeNode value={uuid} title={name} key={uuid}>
        {childrenNode}
      </TreeSelect.TreeNode>
    );
  }

  render() {
    const { structureData } = this.props;

    const masterNode = this.renderTreeNode(structureData);
    const treeProps = this.getPropForTreeSelect();

    return (
      <span className="company-select">
        <TreeSelect {...treeProps}>{masterNode}</TreeSelect>
      </span>
    );
  }
}

RawTreeSelector.defaultProps = {
  structureData: null,
  onlyShowActive: false,
  treeDefaultExpandAll: true,
  multiple: false,
  disabled: false,
  value: undefined,
  style: undefined,
  placeholder: undefined,
  onChange: undefined,
  className: null,
};

RawTreeSelector.propTypes = {
  structureData: PropTypes.object,
  onlyShowActive: PropTypes.bool,
  treeDefaultExpandAll: PropTypes.bool,
  multiple: PropTypes.any,
  disabled: PropTypes.bool,

  value: PropTypes.any,
  style: PropTypes.any,
  placeholder: PropTypes.any,

  onChange: PropTypes.func,
  className: PropTypes.string,
};

export const CompanySelector = withTranslation(['common'])(RawTreeSelector);
