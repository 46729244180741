import { connect } from 'react-redux';

import TeamUpdate from '../../Components/TeamUpdate';
import { updateTeam } from '../../reducers/team';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  updateTeam(...args) {
    return updateTeam(dispatch, ...args);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithDefaults(TeamUpdate));
