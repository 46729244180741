import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { withTranslation } from 'react-i18next';
import { Base, MaintenanceMode, Exception } from 'componentlibrary';
import { createBrowserHistory } from 'history';
import { routesWithPermission } from '../../routes';
import Config from '../../Config';

const defaultHistory = createBrowserHistory();

export class App extends Base {
  constructor(props) {
    super(props);

    this.state = {
      refreshKey: null,
    };
  }

  componentDidMount() {
    this.setAuth();
    try {
      this.getInitialData();
    } catch (err) {
      const { t } = this.props;
      console.error(err);
      super.handleError(t('fetchError'));
    }
  }

  componentWillUnmount() {
    this.props.wsDisconnect();
  }

  componentDidUpdate() {
    const { context, i18n } = this.props;
    const { refreshKey } = this.state;

    if (context.refreshKey !== refreshKey) {
      this.refresh(context.refreshKey);
    }
    if (i18n && context.currentLanguage !== i18n.language) {
      i18n.changeLanguage(context.currentLanguage);
    }
  }

  get permissionDenied() {
    const { t } = this.props;

    return (
      <Exception
        status={403}
        message={t('errors:403')}
      />
    );
  }

  async getIdToken() {
    const { context } = this.props;
    const { getIdToken } = context;

    return getIdToken();
  }

  async getAppConfig() {
    const { getAppConfig } = this.props;
    await getAppConfig();
    this.appConfigInterval = setInterval(getAppConfig.bind(this), 60 * 1000);
  }

  async getInitialData() {
    const { wsConnect, configServiceFetchRequest } = this.props;
    await this.getAppConfig();
    configServiceFetchRequest(Config.configurationService.URL);
    wsConnect(Config.configurationService.WEBSOCKET);

    const {
      getAllTeams, getStructuredCompanies, getContractorCompanies, context
    } = this.props;

    // user does not have teams access, do not fetch any initial data
    const access =
      context.permission &&
      context.permission.teams &&
      context.permission.teams.access;
    if (!access) {
      return;
    }

    const token = await this.getIdToken();

    getAllTeams(token);
    getStructuredCompanies(token);
    getContractorCompanies(token);
  }

  setAuth() {
    const { context, setAuth } = this.props;
    setAuth(context.getIdToken, context.permission, context.user, context.currentLanguage);
  }

  refresh(refreshKey) {
    const { history } = this.props;
    history.push('/teams');

    this.setState({
      refreshKey,
    });
  }

  render() {
    const { t, appConfig, context, history } = this.props;
    if (!appConfig) {
      return null;
    }

    if (appConfig && appConfig.maintenanceMode) {
      return (
        <MaintenanceMode
          title={t('maintenanceMode:serviceDown')}
          description={t('maintenanceMode:pleaseTryAgain')}
        />
      );
    }

    return (
      <Router history={history || defaultHistory}>
        <div style={{ margin: '20px' }}>
          {renderRoutes(
            routesWithPermission(
              context.permission,
              this.permissionDenied,
              appConfig
            )
          )}
        </div>
      </Router>
    );
  }
}

App.defaultProps = {
  appConfig: null
};

App.propTypes = {
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  getAppConfig: PropTypes.func.isRequired,
  appConfig: PropTypes.object,
  setAuth: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  getStructuredCompanies: PropTypes.func.isRequired,
  getAllTeams: PropTypes.func.isRequired,
  getContractorCompanies: PropTypes.func.isRequired,
  wsConnect: PropTypes.func.isRequired,
  configServiceFetchRequest: PropTypes.func.isRequired,
  wsDisconnect: PropTypes.func.isRequired,
};

export default withTranslation(['common', 'errors', 'maintenanceMode'])(App);
